import './MobileDevice.css'

export default function MobileDevice() {
    return (
        <div className="MobileDevice">
            <div className="MobileDevice__main-text">К сожалению платформа не работает с мобильных устройств</div>
            <div className="MobileDevice__secondary-text">Зайдите с компьютера или ноутбука</div>
        </div>
    );
}
