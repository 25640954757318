import './forgetdata.css'
import React, { useRef, useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactDOM from 'react-dom';
import closeButton from '../../images/closeButton.svg'
import WA from '../../images/WA.svg'
import TG from '../../images/TG.svg'

export default function ForgetData({ closeForgetData }) {
  const ref = useRef(null);
  
  
  useEffect(() => {
    function handleClick(event) {
      const target = event.target;
      const formContainer = document.querySelector('#modal_root');
      if (formContainer && formContainer.contains(target)) {
        return;
      }
      if (!ref.current?.contains(target)) {
          // closeForgetData();
      }
    }

    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [closeForgetData]);

  const node = document.querySelector('#modal_root');

  if (!node) return null;


  const overlay = document.createElement('div');
  overlay.classList.add('overlay'); 
  

  return ReactDOM.createPortal((
    <div className={'modal_forget_data'} ref={ref}>
      <div className='header_modal'>
        <button onClick={closeForgetData}><img src={closeButton} /></button>
      </div>
      <h2>Востановление логина или пароля</h2>
      <div className='normal_text first_normal_text'>Обратитесь за помощью в чат компании по <a href="https://wa.me/+79247318585" target='blank'>WhatsApp</a><img src={WA} /></div>
      <div className='normal_text'>Или наш бот в <a href="https://t.me/VlKiberBot" target='blank'>Telegram</a><img src={TG} /></div>
    </div>

  ), node)
}