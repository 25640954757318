
import "./InputWithLabel.css"
import React, { useState } from "react";

export default function InputWithLabel({ textLabel, idInput, placeholder, defaultValue, errorStatus, isText, onChange, onClick,width,className}) {
    const inputClass = errorStatus !== '200' && errorStatus !== '' ? 'login_input error' : 'login_input';
    const [isFocused, setIsFocused] = useState(false);
    function hide_placeholder() {
        setIsFocused(true);
    }

    function handleBlur() {
        setIsFocused(false);
    }
    if (isText) {
        return (
            <label className='label_text' >
                {textLabel}
                <br /><input className={'custom_input'} type="text" id={idInput} placeholder={placeholder} value={defaultValue || ''} readOnly />
                {errorStatus === 200 ? <div>{errorStatus}</div> : null}
            </label>

        )
    }

    else {
        return (
            <label className={`${className==="big"?"label_text_big_font":'label_text'}`} >
                {textLabel}
                <br/><input
                    className={`${inputClass} ${isFocused ? 'focused' : ''}`}
                    type="text"
                    id={idInput}
                    onChange={onChange}
                    placeholder={isFocused ? '' : placeholder}
                    onFocus={hide_placeholder}
                    onBlur={handleBlur}
                    onClick={onClick}
                    defaultValue={defaultValue}
                    style={{width:`${width}px`}}
                ></input>
                {errorStatus !== 200 && errorStatus !== '' ? null : <div>{errorStatus}</div>}
            </label>

        )
    }
}