import { useState } from "react"
import PanelStudents from "./PanelStudents/PanelStudents"
import "./AdminPanel.css"
import PanelWork from "./PanelWork/PanelWork"
import PanelModule from "./PanelModule/PanelModule"

export default function AdminPanel() {
    const [page, setPage] = useState('students')


    return (
        <div className="container-admin-panel">
            <h1 className="container-admin-panel__title">Личный кабинет администратора</h1>
            <div className='container-ModuleBar'>
                <label htmlFor="students" className={page === 'students' ? 'custom-radio checked' : 'custom-radio'}>
                    Ученики
                    <input
                        type="radio"
                        name="r"
                        id="students"
                        onClick={() => setPage("students")}
                    />
                </label>
                <label htmlFor="check_work" className={page === 'check_work' ? 'custom-radio checked' : 'custom-radio'}>
                Проверка работ учеников
                    <input
                        type="radio"
                        name="r"
                        id="check_work"
                        onClick={() => setPage("check_work")}
                    />
                </label>
                <label htmlFor="modules" className={page === 'modules' ? 'custom-radio checked' : 'custom-radio'}>
                Модули
                    <input
                        type="radio"
                        name="r"
                        id="modules"
                        onClick={() => setPage("modules")}
                    />
                </label>
            </div>
            {page == "students" && <PanelStudents />}
            {page == "check_work" && <PanelWork/>}
            {page == "modules" && <PanelModule/>}


        </div>
    )
}