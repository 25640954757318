import SubmitButton from '../../../components/SubmitButton/SubmitButton';
import '../../../components/modal/index.css'
import { ReactComponent as CloseModalSVG } from '../../../images/icons/close-24.svg';

function CommentPanel({ isOpen, onClose, comment, isEdit }) {



    return isOpen ? (
        <div onMouseDown={(e) => e.stopPropagation()}>
            <div
                className={`modal moda_type_question ${isOpen && 'modal_active'}`}
                onMouseDown={onClose}>
                <div
                    className="modal__container modal__container_question"
                    onMouseDown={(e) => e.stopPropagation()}>
                    <div className="modal__header">
                        <button
                            type="button"
                            className="modal__close-button"
                            onClick={() => onClose()}>
                            <CloseModalSVG />
                        </button>
                    </div>
                    <div className="modal__wrapper">
                        <h2 className="modal__question">
                            Комментарий к работе
                        </h2>
                        <div className="modal__buttons modal__buttons_row">
                            <div>Ответ модератора
                                <textarea className='textarea' textLabel={"Описание модуля"} value={comment} readOnly />
                            </div>
                        </div>
                        <SubmitButton onClick={isEdit} content={"Перейти к исправлению"} w={233} h={48} />

                    </div>

                </div>
            </div>
        </div>
    ) : null;
}

export default CommentPanel;
