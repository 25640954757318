import React, { useState, useEffect, useContext } from 'react'
import AuthContext from '../context/AuthContext'
import ListItem from '../components/ListItem/ListItem'
import LoadingScreen from '../components/Loader/Loader'
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import './Account.css'
import '../pages/old/Gallery.css'
import InputWithLabel from '../components/InputWithLabel/InputWithLabel'
import SearchInput from '../components/SearchInput/SearchInput'
import ModulesBar from '../components/ModulesBar/ModuleBar'
import Avatar from '../components/Avatar/Avatar'
import SubmitButton from '../components/SubmitButton/SubmitButton'
import { useGoLinkToModal } from '../hooks/useGoLinkToModal'





const Account = () => {

  let [isLoading, setLoading] = useState(false)
  let { user } = useContext(AuthContext)
  let { token } = useContext(AuthContext)
  let { logoutUser } = useContext(AuthContext)
  let [works, setWorks] = useState([])
  let [modules, setModules] = useState([])
  let [currentModule, setCurrentModule] = useState(null)
  const [inputs, setInputs] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { goLinkToModal } = useGoLinkToModal();

  

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleDataFromSearchInput = (data) => {
    setInputs(data);
  };

  const UploadDataUser = () => {
    user = useContext(AuthContext)
  }
  useEffect(() => {
    document.title = "Личный кабинет"
    let getModules = async () => {
      setLoading(true)
      const response = await axios.get('https://kiberonevl.ru/api/modules/', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        setModules(response.data)
        setLoading(false)
      } else if (response.status !== 200) {
        alert('Токен авторизации устарел. Пожалуйста, войдите в аккаунт заново.')
        setLoading(false)
        logoutUser()
      } else {
      }
    }
    getModules()
  }, [])

  useEffect(() => {
    setWorks(user?.works)
  }, [user])


  function handleModuleChange(event, value) {
    setCurrentModule(value)
  }
  function ChangeDateFormat(dateString) {
    const dateObject = new Date(dateString);
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1; // Месяцы в JavaScript начинаются с 0
    const year = dateObject.getFullYear();
    const formattedDate = `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year}`;
    return formattedDate;
  }

  const navigate = useNavigate();
  const location = useLocation();

  const [selected, setSelected] = useState('radioButton1');

  return (
    isLoading ? <LoadingScreen /> :
      <div className='container' >
        <h2 className='page_name_my_profile'>Моя страница</h2>
        <div id='personal_data'>
          <Avatar uploadData={UploadDataUser} user={user} accept="image/*" />
          <div id='personal_data2'>
            <InputWithLabel idInput={'Name'} textLabel={"Имя"} placeholder={"имя"} defaultValue={user?.first_name + " " + user?.last_name} isText={true} />
            <InputWithLabel idInput={'Age'} textLabel={"Дата рождения"} placeholder={"дата рождения"} defaultValue={ChangeDateFormat(user?.extra.birth_date)} isText={true} />
          </div>
        </div>
        <div className='middle-container'>
          <SearchInput setInputs={handleDataFromSearchInput} /><SubmitButton content={'Добавить работу'} onClick={() => goLinkToModal('/add-work')}
          />
        </div>
        <h3 className='page_name_my_gallery '>Моя галерея работ</h3>
        <ModulesBar onSelected={setSelected}/>
        <div className='galleryCover'>
          <div className='Works_grid'>
            {works?.map((work, index) => (
              (currentModule === null || work.module?.id === currentModule?.id) &&
              <ListItem key={index} work={work} user={user} inputs={inputs ? inputs : ''} status={selected}/>
            )).reverse()}
          </div>
        </div>

      </div>
  )
}

export default Account
