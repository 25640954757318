import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../utils/Api';

export const getWorks = createAsyncThunk(
  'works/getWorks',
  async (_, { rejectWithValue }) => {
    try {
      const res = await api.getWorks();
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createWork = createAsyncThunk(
  'works/createWork',
  async (formData, { rejectWithValue, dispatch }) => {
    try {
      const res = await api.createWork(formData);
      // потом убрать
      // console.log('createWork(formData) => res', formData, res);
      // обновить стейт работ
      dispatch(getWorks());
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getWorkById = createAsyncThunk(
  'works/getWorkById',
  async (id, { rejectWithValue }) => {
    try {
      const res = await api.getWorkById(id);
      // потом убрать
      // console.log('getWorkById(id) => res', id, res);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const putWorkById = createAsyncThunk(
  'works/putWorkById',
  async ({ id, data }, { rejectWithValue, dispatch }) => {
    try {
      const res = await api.putWorkById(id, data);
      // потом убрать
      // console.log('putWorkById(id, data) => res', id, data, res);
      // обновить стейт работ
      await dispatch(getWorks());
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const patchWorkById = createAsyncThunk(
  'works/patchWorkById',
  async ({ id, data }, { rejectWithValue, dispatch }) => {
    try {
      const res = await api.patchWorkById(id, data);
      // потом убрать
      // console.log('patchWorkById(id, data) => res', id, data, res);
      // обновить стейт работ
      await dispatch(getWorks());
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteWorkById = createAsyncThunk(
  'works/deleteWorkById',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const res = await api.deleteWorkById(id);
      // потом убрать
      // console.log('deleteWorkById(id) => res', id, res);
      dispatch(getWorks());
      return res;
    } catch (err) {
      return rejectWithValue(err);
    } finally {
    }
  },
);
