import React, { useState, useEffect, useLayoutEffect, useContext } from 'react'
import AuthContext from '../context/AuthContext'

import '../style/notes.css'


const Notes = () => {
    let {user} = useContext(AuthContext)
    let {token} = useContext(AuthContext)
    let {logoutUser} = useContext(AuthContext)
    let [isLoading, setLoading] = useState(false)

    let [note, setNote] = useState({
        id:null,
        title:"",
        text:"",
        time:"",
    })
    let [notes, setNotes] = useState([])

    useEffect(() => {
        document.title = "Заметки"

        return () => {
            fetching(false)
        }
    }, [])

    useEffect(() => {
        setNotes(user?.notes)
    }, [user])

    let isEmpty = (note) => {
        return (note.text === '' && note.title === '')
    }

    let fetching = (isDelete) => {
        let n = localStorage.getItem('note') ? JSON.parse(localStorage.getItem('note')) : null
        if (n === null) return
        else localStorage.removeItem('note')
        if (isDelete || isEmpty(n)) deleteNote(n)
        else (n.id === 'new' || n.id === 'newnew') ? createNote(n) : updateNote(n)
    }

    let createNote = async (note) => {
        if (isLoading) {
            alert('Подождите, идет загрузка')
            return
        }
        setLoading(true)
        let response = await fetch(`https://kiberonevl.ru/api/notes/create/`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer ' + token,
            },
            body: JSON.stringify({'user':user.user_id, 'title':note.title, 'text':note.text})}
        )
        if (response.ok) {
            let data = await response.json()

            let flag = true
            let updatedNotes = notes.map(item =>
                {
                    if(item.id === 'new' && flag) {
                        flag = false
                        item.id = data.id
                        item.time = data.time
                    }
                return item
            })
            if (note.id === 'newnew') setNotes(notes => [...updatedNotes, {id:'new', title:'', text:'', time:''}])
            else setNotes(notes => updatedNotes)
            setLoading(false)
        } else if (response.status === 401) {
            alert('Токен авторизации устарел. Пожалуйста, войдите в аккаунт заново.')
            setLoading(false)
            logoutUser()
        } else {
            alert('Что-то пошло не так!')
            setLoading(false)
        }
    }

    let updateNote = async (note) => {
        if (isLoading) {
            alert('Подождите, идет загрузка')
            return
        }
        setLoading(true)
        let response = await fetch(`https://kiberonevl.ru/api/notes/${note.id}/`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer ' + token,
            },
            body: JSON.stringify({'user':user.user_id, 'title':note.title, 'text':note.text})}
        )
        if (response.ok) {
            setLoading(false)
        } else if (response.status === 401) {
            alert('Токен авторизации устарел. Пожалуйста, войдите в аккаунт заново.')
            setLoading(false)
            logoutUser()
        } else {
            alert('Что-то пошло не так!')
            setLoading(false)
        }
    }

    let deleteNote = async (note) => {
        if (isLoading) {
            alert('Подождите, идет загрузка')
            return
        }
        setLoading(true)
        if (note.id !== 'new' && note.id !== 'newnew') {
            let response = await fetch(`https://kiberonevl.ru/api/notes/${note.id}/`, {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':'Bearer ' + token,
                }
            })
            if (response.ok) {
               setLoading(false)
            } else if (response.status === 401) {
                alert('Токен авторизации устарел. Пожалуйста, войдите в аккаунт заново.')
                setLoading(false)
                logoutUser()
            } else {
                alert('Что-то пошло не так!')
                setLoading(false)
            }
        }
        let newNotes = []
        let flag = true
        for (let i = 0; i < notes.length; i++) {
            if(notes[i]?.id === note.id && flag) {
                flag = false
                continue
            }
            newNotes.push(notes[i])
        }
        setNotes(notes => newNotes)
    }

    let getTime = (note) => {
        if (note.id !== 'new') return new Date(note.time).toLocaleDateString()
        else return new Date().toLocaleDateString()
    }

    function openNote(id, event){
        if (id !== 'new') {
            if (note.id != null) localStorage.setItem('note', JSON.stringify(note))
            fetching(false)
            if (note.id !== id) setNote(note => (notes.find(item => item.id === id)))
        }
    }

    function newNote(event){
        if(note.id != null) localStorage.setItem('note', JSON.stringify(note))
        if(note.id === 'new') localStorage.setItem('note', JSON.stringify({...note, id:'newnew'}))
        else setNotes(notes => ([...notes, {id:'new', title:'', text:'', time:''}]))
        fetching(false)
        setNote(note => ({id:'new', title:'', text:'', time:''}))
    }

    function handleTitleChange(event) {
        localStorage.setItem('note', JSON.stringify({...note, title:event.target.value}))
        setNote(note => ({...note, title:event.target.value}))
        let updatedNotes = notes.map(item =>
            {
                if (item.id === note.id){
                    return {...item, title:event.target.value}
            }
            return item
        })
        setNotes(notes => (updatedNotes));
    }

    function handleTextChange(event) {
        localStorage.setItem('note', JSON.stringify({...note, text:event.target.value}))
        setNote(note => ({...note, text:event.target.value}))
        let updatedNotes = notes.map(item =>
            {
                if (item.id === note.id){
                    return {...item, text:event.target.value}
            }
            return item
        })
        setNotes(notes => (updatedNotes));
    }

    function deleteBtnClick(event) {
        localStorage.setItem('note', JSON.stringify(note))
        setNote(note => ({id:null, title:'', text:'', time:''}))
        fetching(true)
    }

  return (
    <>
    <div className='b'>

    <div className='leftMenu'>

      <div className='addNote'>
         <button className='addNoteBtn' onClick={newNote}>Добавить новую заметку</button>
      </div>

      <div className='allNotes'>
        {notes?.map(note => (
            <div className='note' onClick={(e) => openNote(note.id, e)}>
                <img src={require('../style/line.png')} className='line' />
                <h3 className='name'>{note.title}</h3>
                <h3 className='time'>{getTime(note)}</h3>
            </div>
        )).reverse()}
      </div>

    </div>

    <img src={require('../style/bigLine.png')} className='bigLine'/>

    {note?.id != null && <div className='noteText'>
    <textarea value={note?.title} onChange={handleTitleChange} maxLength='50' placeholder='Название заметки' className='inp' style={{backgroundColor: 'white'}}/>
    {/*add current time */}
    <textarea value={note?.text} onChange={handleTextChange} placeholder='Содержание заметки' className='area' style={{backgroundColor: 'white'}}/>

    </div>}

    </div>
{note?.id != null && <button className='delBtn' onClick={deleteBtnClick}>Удалить</button>}
    </>
  )
}

export default Notes
