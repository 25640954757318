export const getToken = () => {
  const refresh = localStorage.getItem('refreshToken');
  const access = localStorage.getItem('accessToken');
  return { refresh, access };
};

export const setToken = (token) => {
  const { access = undefined, refresh = undefined } = token;
  if (refresh) {
    localStorage.setItem('refreshToken', refresh);
  }
  if (access) {
    localStorage.setItem('accessToken', access);
  }
};

export const checkToken = () => {
  const token = getToken();
  if (!token) {
    return false;
  }
  return token;
};
