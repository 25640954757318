import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as MoreArrowSVG } from '../../../images/icons/arrow_downward_alt-16.svg';

const DescriptionElement = ({ title, text }) => {
  const [textOpen, setTextOpen] = useState(false);
  const [overflowActive, setOverflowActive] = useState(false);
  const textRef = useRef();

  function isOverflowActive(event) {
    return event.offsetHeight < event.scrollHeight || event.offsetWidth < event.scrollWidth;
  }

  useEffect(() => {
    if (isOverflowActive(textRef.current)) {
      setOverflowActive(true);
      return;
    }

    setOverflowActive(false);
  }, [isOverflowActive]);

  return (
    <div className="work__wrapper">
      {title}
      <div className="work__description-wparrer">
        <p
          ref={textRef}
          className={`work__description-text ${textOpen ? 'is-open' : ''}`}>
          {text}
        </p>
      </div>
      {!textOpen && !overflowActive ? null : (
        <button
          onClick={() => setTextOpen((state) => !state)}
          type="button"
          className={`work__more-button ${textOpen ? 'is-open' : ''}`}>
          {textOpen ? 'Свернуть' : 'Подробнее'}
          <MoreArrowSVG />
        </button>
      )}
    </div>
  );
};

export default DescriptionElement;
