import React, { useRef, useEffect, useContext, useState } from 'react';
import closeButton from '../../images/icons/close-24.svg'
import closeBlue from '../../images/icons/cross.svg'
import "./AddNewWork.css"
import InputWithLabel from '../InputWithLabel/InputWithLabel';
import AuthContext from '../../context/AuthContext'
import add_photo_svg from '../../images/icons/icon panel=photo.svg'
import SubmitButton from '../SubmitButton/SubmitButton';
import CustomDropdown from '../CustomDropdown/CustomDropdown';
import PublishModal from '../PublishModal/PublishModal';
import LoadingScreen from '../Loader/Loader';
import { useHandleSubmitAddWork } from './useHandleSubmitAddWork';

export default function AddNewWork({ onClose }) {
    const { HandleSubmitAddWork } = useHandleSubmitAddWork
    const [isLoading, setLoading] = useState(false)
    const [errorTitle, setErrorTitle] = useState("200");
    const [errorScreenshots, seterrorScreenshots] = useState(null)
    const [errorWork, setErrorWork] = useState(null)
    const [isOpenPublishModal, setOpenPublishModal] = useState(false);
    const [errorModal, setErrorModal] = useState(null)
    const [modules, setModules] = useState([])
    const [src, setSrc] = useState([])
    const [selectedFile, setSelectedFile] = useState(null);
    const [work, setWork] = useState({
        screenshots: [],
        title: "",
        module: null,
        file: null,
        comment: "",
        is_public: null,
    })

    useEffect(() => {
        let getModules = async () => {
            let response = await fetch('https://kiberonevl.ru/api/modules/', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
            })
            if (response.ok) {
                let data = await response.json()
                setModules(data)
            } else if (response.status === 401) {
                alert('Токен авторизации устарел. Пожалуйста, войдите в аккаунт заново.')
                logoutUser()
            } else {

                alert('Что-то пошло не так!', response.status, '!')
            }
        }
        getModules()
    }, [])

    let { user } = useContext(AuthContext)
    let { token } = useContext(AuthContext)
    let { logoutUser } = useContext(AuthContext)

    function handleTitleChange(event) {
        const trimmedValue = event.target.value.trim(); // Удаляем пробелы в начале и конце строки
        if (trimmedValue.length >= 35) {
            setErrorTitle("Название не должно превышать 35 символов");
            return;
        }
        else {
            const capitalizedTitle = trimmedValue.charAt(0).toUpperCase() + trimmedValue.slice(1);
            setWork({ ...work, title: capitalizedTitle });
            setErrorTitle("200");
        }
    }

    function handleCommentChange(event) {
        setWork({ ...work, comment: event.target.value })
    }

    function handleModuleChange(event) {
        const selectedModule = (modules.filter(module => module.id === event.id))[0];
        setWork({ ...work, module: selectedModule })
        setErrorModal(null)
    }

    function handleScreenshotsChange(event) {
        console.log(event.target.files); // Проверка, чтобы убедиться, что файлы правильно передаются
        if ((work?.screenshots.length + event.target.files.length) > 3) {
            seterrorScreenshots("Скриншота может быть только три")
            return
        }

        if (event.target.files.length > 3) {
            seterrorScreenshots("Скриншота может быть только три.")
            const updatedScreenshots = Array.from(event.target.files).slice(0, 3);
            console.log(updatedScreenshots);
            setWork(prevWork => ({
                ...prevWork,
                screenshots: [...prevWork.screenshots, ...(Array.from(event.target.files).slice(0, 3))],
            }));
            return;
        }



        setWork(prevWork => ({
            ...prevWork,
            screenshots: [...prevWork.screenshots, ...event.target.files],
        }));
        getScreenshotsURL([...event.target.files])
        seterrorScreenshots(null)
    }

    function handleCheckChangeIsModal(event) {

        setWork(prevWork => ({
            ...prevWork,
            is_public: true
        }));


    }

    function handleCheckChangeIsNotModal(event) {

        setWork(prevWork => ({
            ...prevWork,
            is_public: false
        }));
    }

    useEffect((e) => {
        if (work.is_public !== null)
            handleSubmit(e)
    }, [work.is_public]);


    function getScreenshotsURL(fileList) {
        if (!fileList || fileList.length === 0) {
            setSrc([]);
            return;
        }
        let newSrc = [];
        Array.from(fileList).forEach((file) => {
            const imageUrl = URL.createObjectURL(file);

            // Check if the image URL already exists in newSrc
            if (!newSrc.includes(imageUrl)) {
                newSrc.push(imageUrl);
            }
        });
        setSrc((prevSrc) => [...prevSrc, ...newSrc]);
    }

    async function handleSubmit(e) {

        if (e) {
            e.preventDefault();
        } else {
            console.error("Invalid event object:", e);
        }
        if (work.file) {
            setLoading(true)
            let form_data = new FormData()
            form_data.append('title', work.title)
            form_data.append('module', work.module?.id)
            form_data.append('file', work.file, work.file.name)
            form_data.append('comment', work.comment)
            form_data.append('is_public', work.is_public)
            form_data.append('user', user.id)
            let response = await fetch(`https://kiberonevl.ru/api/works/create/`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                body: form_data
            })
            if (response.ok) {
                let data = await response.json()
                for (let ss of work.screenshots) {
                    let form_data_screenshots = new FormData()
                    form_data_screenshots.append('work', data.id)
                    form_data_screenshots.append('screenshot', ss, ss.name)
                    let ss_response = await fetch(`https://kiberonevl.ru/api/screenshots/create/`, {
                        method: 'POST',
                        headers: {
                            'Authorization': 'Bearer ' + token
                        },
                        body: form_data_screenshots
                    })
                    if (!ss_response.ok) {
                        alert('Что-то пошло не так!', response.status, '!')
                        break
                    }
                }
                setLoading(false)
                onClose();
                window.location.reload()

            } else if (response.status === 400) {
                alert('Введены некорректные данные!')
                setWork({ ...work, is_public: null })
                setLoading(false)
            } else if (response.status === 401) {
                alert('Токен авторизации устарел. Пожалуйста, войдите в аккаунт заново.')
                setLoading(false)
                logoutUser()
            } else {
                alert('Что-то пошло не так!', response.status, '!')
                setLoading(false)
            }
        }
        window.location.reload()
    }


    function handleFileChange(event) {
        event.preventDefault();
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setWork({ ...work, file: selectedFile });
            setSelectedFile(selectedFile);
            setErrorWork(null);
        } else {
            // Handle the case where no file is selected
            console.error("No file selected");
        }
    }

    function handleDeleteScreenshot(index, e) {
        e.preventDefault()
        const updatedScreenshots = [...work.screenshots];
        updatedScreenshots.splice(index, 1);
        setWork(prevWork => ({
            ...prevWork,
            screenshots: updatedScreenshots,
        }));
        // Обновите также массив src, чтобы удалить соответствующий URL
        const updatedSrc = [...src];
        updatedSrc.splice(index, 1);
        setSrc(updatedSrc);
    }

    const fileInputRef = useRef(null);



    const handleInputClick = () => {
        // Вызывает метод click на input, эмулируя клик на input
        fileInputRef.current.click();
    };

    const [isFocused, setIsFocused] = useState(false);
    function hide_placeholder() {
        setIsFocused(true);
    }

    function handleBlur() {
        setIsFocused(false);
    }

    function validateInput(e) {
        e.preventDefault();
        if (isLoading) {
            alert('Подождите, идет загрузка')
            return
        }
        if (!work?.title || /^\s*$/.test(work?.title)) {
            setErrorTitle("Проект должен иметь название")
            return
        }
        if (work.module == undefined) {
            setErrorModal("Выберите модуль проекта")
        }
        if (work?.screenshots.length > 3) {
            seterrorScreenshots("Скриншота может бытьа только три")
            return
        }
        if (work?.file == null) {
            setErrorWork('В проект необходимо добавить файл с работой')
            return
        }
        if (work?.file.size == 0) {
            setErrorWork('Файл с работой не должен быть пустым')
            return
        }
        if (work?.file.size >= 512 * 1024 * 1024) {
            setErrorWork('Размер файла не должен превышать 512 Мбайт')
            return
        }

        setOpenPublishModal(true)
    }
    return !isLoading ? (
        <div className="container_add_new_work" onMouseDown={(e) => e.stopPropagation()}>
            <div className='header_add_new_work'>
                <button type="button" onClick={() => onClose()} ><img style={{ width: "24px" }} className={"modal__close_img"} src={closeButton} /></button>
            </div>
            <form className='form_add_new_work'>
                <div className='form_uploader_work'>
                    <h2 className='title'>Добавить новую работу</h2>
                    <div className='text_field'>
                        <div>
                            <InputWithLabel
                                textLabel={'Название работы'}
                                idInput={'title_new_work'}
                                placeholder={'Введите название работы'}
                                isText={false}
                                errorStatus={errorTitle}
                                onChange={handleTitleChange} />
                            {errorTitle === "200" ? null : <span style={{ color: "#EF4545", marginLeft: "3%" }}>{errorTitle}</span>}

                        </div>
                        <div>
                            <CustomDropdown modules={modules} onSelectModule={handleModuleChange} />
                            {errorModal === null ? null : <span style={{ color: "#EF4545", marginLeft: "3%", marginTop: "2px" }}>{errorModal}</span>}</div>


                    </div>

                    <div className="file-input-container">
                        <label className="file-input-label">
                            Загрузите файл с работой
                        </label>
                        <div className='uploader_file'>
                            <div className="file-info">
                                {selectedFile ? (
                                    <span>{selectedFile.name}</span>
                                ) : (
                                    <span>Файл не выбран</span>
                                )}

                            </div>

                            <label className='file_upload_btn'><input
                                id="files"
                                name="files"
                                type="file"
                                onChange={handleFileChange}
                                className="file-input"
                            />Загрузить</label>


                        </div>
                        {errorWork === null ? <div className='mark_for_size_file'>Файл не больше 500 мб </div> : <span style={{ color: "#EF4545", marginLeft: "1%" }}>{errorWork}</span>}
                    </div>
                </div>
                <div className='paint'>
                    <div>Загрузить скриншоты работы</div>
                    <div className='screenshots_gellary_container'>

                        <div className='gellary_screenshots'>
                            {src.map((item, index) => (<div>
                                <div className="box-with-image" key={index} style={{ backgroundImage: `url(${item})` }}>
                                    <button className="scroos_screenshots" onClick={(e) => handleDeleteScreenshot(index, e)}><img src={closeBlue} /></button>
                                </div>
                                {index === 0 ? <div className='add_work__photo__main_photo'>Основное фото</div> : null}
                            </div>
                            ))}
                        </div>
                        <div className='input_new_screenshots' onClick={handleInputClick}>
                            <img src={add_photo_svg} />
                            <div>Добавить изображение</div>
                            <input type="file"
                                required
                                multiple
                                accept="image/*"
                                onChange={handleScreenshotsChange}
                                ref={fileInputRef} />
                        </div>
                    </div>
                    {errorScreenshots === null ? null : <span style={{ color: "#EF4545", marginTop: "1%" }}>{errorScreenshots}</span>}

                </div>
                <div className='description_work'>
                    <div className='title_description_work'>Описание к работе</div>
                    <textarea placeholder={isFocused ? '' : 'Оставить описание к работе (необязательно)'}
                        value={work.comment}
                        onChange={handleCommentChange}
                        name="aboutWork"
                        onFocus={hide_placeholder}
                        onBlur={handleBlur}
                        className='textarea_description_work' />
                </div>
                <div className='sub_add_work'><SubmitButton content={"Готово"} w={280} h={48} onClick={(e) => validateInput(e)} /></div>
                {isOpenPublishModal ? (
                    <PublishModal
                        isOpen={isOpenPublishModal}
                        onClose={() => setOpenPublishModal(false)}
                        toAgree={handleCheckChangeIsModal}
                        toRefuse={handleCheckChangeIsNotModal}
                        question="Добавить работу в галерею?"
                    />
                ) : null}
            </form>
        </div>
    ) : <LoadingScreen />
}