import React, { useEffect, useState } from 'react';
import CustomScrollbars from '../../components/gallery/FormGallery/CustomScrollbars';
import { useForm } from '../../hooks/useForm';
import '../../components/gallery/FormGallery/FormGallery.css';
import { ReactComponent as ArrowDropdownSVG } from '../../images/icons/arrow_drop_down-24.svg';

const CustomDropdown = ({ modules, onSelectModule, def }) => {
  const [selectModule, setSelectModule] =
    useState({
      title: def?.short_title_with_default || 'Выберите модуль',
      id: def?.id || null
    })
  const [values, setValues] = useState('');
  const [dropdown, setDropdown] = useState([]);
  const [open, setOpen] = useState(false);
  const [filtredModules, setFiltredModules] = useState(modules);

  const handleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setFiltredModules(modules)
  }, [modules])

  useEffect(() => {
    setFiltredModules(filterModiles(modules, values));
  }, [values]);


  // Закрыть по оверлею
  const handleOverlay = (e) => {
    if (e.target === e.currentTarget) {
      handleOpen();
    }
  };

  function checkedTags(id) {
    const selectedModule = modules.find((module) => module.id === id);
  
    if (selectedModule) {
      setSelectModule((prevSelectModule) => ({
        title: selectedModule.short_title_with_default,
        id: id
      }));
  
      // Use the updated selectModule directly
      onSelectModule({
        title: selectedModule.short_title_with_default,
        id: id
      });
      setOpen(false)
    }
  }
  


  const handleChange = (event) => {
    setValues(event.target.value);
    filterModiles(modules, values)
  };

  const filterModiles = (modules, value) => {
    if (value && typeof value === 'string') {
      const filteredModules = modules.filter((i) =>
        i.short_title_with_default
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      );
      return filteredModules;
    } else {
      return modules;
    }
  };


  // Фильтр модулей
  useEffect(() => {
    setFiltredModules(() => filterModiles(modules, values?.modules));
  }, [values?.modules]);

  return (
    <div className="search__dropdown dropdown">
      <legend>Выберите модуль</legend>
      <button
        className="dropdown__button"
        type="button"
        aria-expanded={open}
        aria-controls="dropdown__box"
        aria-haspopup="box"
        onClick={() => handleOpen()}>
        {selectModule.title}
        <ArrowDropdownSVG />
      </button>
      {open ? (
        <div
          aria-hidden={open}
          aria-modal="true"
          id="dropdown__box"
          role="box"
          className="dropdown__box"
          onClick={(e) => {
            handleOverlay(e);
          }}>
          <div className="dropdown__search-field  search-field">
            <input
              type="search"
              name="modules"
              className="search-field__input"
              placeholder="Найти работу по модулю"
              onChange={handleChange}
              value={values}
            />
            {values?.modules ? (
              <button
                type="button"
                className="search-field__clean"
                aria-label="Очистить форму поиска"
                onClick={() => setValues({ ...values, modules: '' })}
              />
            ) : null}
          </div>
          <CustomScrollbars
            className="dropdown__scroll-box"
            autoHeight
            autoHeightMin={48}
            autoHeightMax={192}>
            <ul className="dropdown__list">
              {filtredModules.length > 0 ? (
                filtredModules?.map((module) => {
                  const { id } = module;
                  return (
                    <li
                      className="dropdown__item"
                      key={id}>
                      <input
                        className="dropdown__checkbox"
                        type="button"
                        id={id}
                        onClick={() => checkedTags(id)}

                      />
                      <label
                        htmlFor={id}
                        className="dropdown__label">

                        <span>{module.short_title_with_default}</span>
                      </label>
                    </li>
                  );
                })
              ) : (
                <li className="dropdown__item">
                  <span className="dropdown__label">Модуль не найден</span>
                </li>
              )}
            </ul>
          </CustomScrollbars>
        </div>
      ) : null}
    </div>
  );
};

export default CustomDropdown;
