import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { workSelectors } from '../../store';
import {
  deleteWorkById,
  getWorkById,
  getWorks,
  patchWorkById,
} from '../../store/works/actions';
import { ReactComponent as CloseModalSVG } from '../../images/icons/close-24.svg';
import ElipseAvatar from '../../images/Ellipse-avatar-40.svg';
import AuthContext from '../../context/AuthContext';
import './index.css';
import ImageSwiper from '../../components/workPageElements/ImagesSwiper';
import LoadingScreen from '../../components/Loader/Loader';
import DescriptionElement from '../../components/workPageElements/DescriptionElement';
import DeleteModal from '../../components/modal/DeleteModal';
import EditWork from '../../components/EditWork/EditWork';
import { ReactComponent as DownloadSVG } from '../../images/icons/download-24.svg';
import { ReactComponent as StylusSVG } from '../../images/icons/stylus-24.svg';
import { ReactComponent as ChatSVG } from '../../images/icons/chat_bubble-24.svg';
import { ReactComponent as TrashSVG } from '../../images/icons/tеrash-24.svg';
import { ReactComponent as CheckedSVG } from '../../images/icons/check-box-24.svg';
import { ReactComponent as UncheckedSVG } from '../../images/icons/check-box-outline-blank-24.svg';
import { useGoLinkToModal } from '../../hooks/useGoLinkToModal';
import { StatusElement } from '../../components/StatusElement';
import { switchBodyScrollbar } from '../../utils/switchBodyScrollbar';
import PublishWorkModal from '../../components/modal/PublishWorkModal';
import { Token } from '@mui/icons-material';
import { getToken } from '../../utils/tokenStorage';
import CommentPanel from './CommentPanel/CommentPanel';

const WorkPage = ({ onClose, linkState }) => {
  const location = useLocation()
  let { token } = useContext(AuthContext)
  const stateStaffFromLink = location?.state?.backgroundLocation.state?.staff;

  const [isEditWorkOpen, setisEditWorkOpen] = useState(false);
  const [isMyWork, setIsMyWork] = useState(false);
  const [isOpenDelete, setOpenDelete] = useState(false);
  const [isOpenPublishWorkModal, setOpenPublishWorkModal] = useState(false);
  const [comment, setComment] = useState("")
  const [commentWork, setcommentWork] = useState(false)


  function handleInput(Event) {
    setComment(Event.target.value);
  }

  const { user } = useContext(AuthContext);

  const navigate = useNavigate();
  const { id } = useParams();

  const { work, isLoadingWork, isLoadingEditWork, errorMessage, isSuccess } =
    useSelector(workSelectors);
  const dispatch = useDispatch();

  const { goLinkToModal } = useGoLinkToModal();

  const {
    title,
    module: { description: moduleDescription, title: moduleTitle } = {},
    user: {
      first_name,
      id: userId,
      last_name,
      extra: { avatar = ElipseAvatar } = {},
    } = {},
    comment: workDescription = '',
    is_moderated = false,
    is_public = false,
    is_rejected = false,
    screenshots,
  } = work;

  // Опубликовать работу
  function publish() {
    const data = { is_public: !work.is_public };
    dispatch(patchWorkById({ id, data })).then(() => {
      dispatch(getWorks());
      setOpenPublishWorkModal(false);
    });
  }
  // Удалить работу
  const handleDeleteWork = () => {
    setOpenDelete(false);
    dispatch(deleteWorkById(id)).then(() => {
      onClose();
    });
    onClose();
    window.location.reload()
  };

  // скачать файл
  const download = async () => {
    const blob = await fetch(work.file).then((r) => r.blob());
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = work.file.substring(work.file.lastIndexOf('/') + 1);
    a.click();
  };

  useEffect(() => {
    userId === user?.id ? setIsMyWork(true) : setIsMyWork(false);
  }, [work, user]);

  useEffect(() => {
    dispatch(getWorkById(id));
  }, [id]);

  useEffect(() => {
  }, [work]);

  async function checkWork(status) {
    let response = await fetch(`https://kiberonevl.ru/api/works/${id}/`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify({ 'reason': comment, "is_moderated": true, "is_rejected": status })
    })
    if (response.ok) {
      window.location.reload();
      let data = await response.json()
    } else if (response.status === 401) {
      alert('Токен авторизации устарел. Пожалуйста, войдите в аккаунт заново.')
    } else {
    }
  }

  async function publicWork(status) {
    let response = await fetch(`https://kiberonevl.ru/api/works/${id}/`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify({ 'is_public': status })
    })
    if (response.ok) {
      window.location.reload();
      let data = await response.json()
    } else if (response.status === 401) {
      alert('Токен авторизации устарел. Пожалуйста, войдите в аккаунт заново.')
    } else {
    }
  }



  function openEdit() {
    setcommentWork(false)
    setisEditWorkOpen(true)
  }

  return (
    <>
      {isLoadingWork ? (
        <LoadingScreen />
      ) : (
        isSuccess && (
          <div className="modal__work work">
            <div
              aria-modal="true"
              className="work__container"
              onMouseDown={(e) => e.stopPropagation()}>
              <div className="work__header">
                <button
                  type="button"
                  className="work__close-button"
                  onClick={() => onClose()}>
                  <CloseModalSVG />
                </button>
              </div>
              <div className="work__content">
                <div className="work__top-wrapper">
                  <h1 className="work__title">{title}</h1>
                  <DescriptionElement
                    title={
                      <h2 className="work__module-title">{moduleTitle}</h2>
                    }
                    text={moduleDescription}
                  />
                  {isMyWork ? (
                    <div className="work__status">
                      <StatusElement
                        work={work}
                        user={user}
                      />
                    </div>
                  ) : null}
                </div>
                {screenshots?.length > 0 && (
                  <div className="work__slider">
                    {/* ADDD */}
                    <ImageSwiper slides={screenshots}/>
                  </div>
                )}
                {workDescription?.length > 1 && (
                  <DescriptionElement
                    title={
                      <h3 className="work__description-title">
                        Описание работы
                      </h3>
                    }
                    text={workDescription}
                  />
                )}

                <div className="work__footer">
                  <div className="work__author">
                    <img
                      className="work__avatar"
                      src={avatar}
                      alt="Аватар"
                    />
                    <div className="work__author-wrapper">
                      <p className="work__author-name">{first_name}</p>
                      <p className="work__author-name">{last_name}</p>
                    </div>
                  </div>

                  {isMyWork ? (
                    <button
                      className="work__publish-btn"
                      onClick={() => {
                        is_public ? publicWork(false) : publicWork(true);
                      }}>
                      {is_public ? <CheckedSVG /> : <UncheckedSVG />}
                      Опубликовать работу в галерее
                    </button>
                  ) : null}
                </div>
                {stateStaffFromLink && <div className='checking_work'>
                  <h2 className='checking_work__title'>Замечания к работе</h2>
                  <textarea className='checking_work__textarea' onChange={handleInput} placeholder='Введите замечания к работе'></textarea>
                  <div className='checking_work_wrapper'>
                    <button className='checking_work_wrapper__btn_accept' onClick={() => checkWork(false)}>Одобрить</button>
                    <button className='checking_work_wrapper__btn_reject' onClick={() => checkWork(true)}>Отклонить</button>
                  </div>
                </div>}
              </div>
            </div>
            {isMyWork ? (
              <ul
                className="work__side-panel side-panel"
                onMouseDown={(e) => e.stopPropagation()}>
                <li className="side-panel__item">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      download();
                    }}
                    type="button"
                    className="side-panel__button">
                    <DownloadSVG />
                  </button>
                  <span className="side-panel__button-name">Скачать</span>
                </li>
                <li className="side-panel__item">
                  <button
                    onClick={(e) => {
                      setisEditWorkOpen(true)
                      // e.preventDefault();
                      // switchBodyScrollbar(false);
                      // // goLinkToModal(`/works/${work.id}/edit`, work, linkState);
                      // navigate(`/works/${work.id}/edit`);
                    }}
                    type="button"
                    className="side-panel__button">
                    <StylusSVG

                    />
                  </button>
                  <span className="side-panel__button-name">Редактировать</span>
                </li>
                <li className="side-panel__item">
                  <button
                    onClick={() => {
                      setcommentWork(true)
                    }}
                    type="button"
                    className="side-panel__button">
                    <ChatSVG />
                  </button>
                  <span className="side-panel__button-name">Комментарии</span>
                </li>
                <li className="side-panel__item">
                  <button
                    onClick={() => {
                      setOpenDelete(true);
                    }}
                    type="button"
                    className="side-panel__button">
                    <TrashSVG />
                  </button>
                  <span className="side-panel__button-name">Удалить</span>
                </li>
              </ul>
            ) : null}

          </div>
        )
      )}
      {/* {!isLoadingWork && errorMessage && (
        <div className="modal__work work">
          <div
            aria-modal="true"
            className="work__container"
            onMouseDown={(e) => e.stopPropagation()}>
            <h1>{errorMessage || 'Авторизуйтесь'}</h1>
          </div>
        </div>
      )} */}
      {commentWork ?
        <CommentPanel
          isOpen={commentWork}
          onClose={() => setcommentWork(false)}
          comment={work.reason}
          isEdit={() => openEdit()} />
        : null}
      {isOpenDelete ? (
        <DeleteModal
          isOpen={isOpenDelete}
          onClose={() => setOpenDelete(false)}
          toAgree={() => handleDeleteWork()}
          toRefuse={() => setOpenDelete(false)}
          question="Уверены, что хотите удалить работу?"
        />
      ) : null}
      {isOpenPublishWorkModal ? (
        <PublishWorkModal
          isOpen={isOpenPublishWorkModal}
          onClose={() => setOpenPublishWorkModal(false)}
          toAgree={() => publish()}
          toRefuse={() => setOpenPublishWorkModal(false)}
        />
      ) : null}

      {isEditWorkOpen && <EditWork isOpen={isEditWorkOpen} onClose={() => setisEditWorkOpen(!isEditWorkOpen)} EditWork={work} idWork={id} tokenAuth={token} />}
    </>
  );
};

export default WorkPage;
