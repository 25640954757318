import './../index.css';
import { useContext, useEffect, useRef } from 'react';
import { ReactComponent as CloseModalSVG } from '../../../images/icons/close-24.svg';
import AuthContext from '../../../context/AuthContext';
import { switchBodyScrollbar } from '../../../utils/switchBodyScrollbar';

function Exit({ onClose }) {
  useEffect(() => {
    document.title = 'Выход';
  }, []);

  const { logoutUser } = useContext(AuthContext);

  return (
    <div
      className={`modal moda_type_question modal_active`}
      onMouseDown={onClose}>
      <div
        className="modal__container modal__container_question"
        onMouseDown={(e) => e.stopPropagation()}>
        <div className="modal__header">
          <button
            type="button"
            className="modal__close-button"
            onClick={() => onClose()}>
            <CloseModalSVG />
          </button>
        </div>
        <div className="modal__wrapper">
          <h2 className="modal__question">Уверен, что хочешь выйти?</h2>
          <div className="modal__buttons modal__buttons_row">
            <button
              type="button"
              className="button_secondary button_secondary_white"
              onClick={() => onClose()}>
              Отмена
            </button>
            <button
              type="button"
              className="button_secondary button_secondary_blue"
              onClick={() => {
                switchBodyScrollbar(false);
                logoutUser();
              }}>
              Выйти
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Exit;
