import React from 'react';
import "./index.css"

const OtherModal = ({ isOpen, onClose, children }) => {
  return isOpen ? (
    <div onMouseDown={(e) => e.stopPropagation()}>
      <div
        className={`modal modal_type_other ${isOpen && 'modal_active'}`}
        onMouseDown={onClose}>
        <div onMouseDown={(e) => e.stopPropagation()} className='modal__swipper'> {children}</div>
      </div>
    </div>
  ) : null;
};

export default OtherModal;
