import './../index.css';
import { createPortal } from 'react-dom';
import { useEffect, useRef } from 'react';
import { switchBodyScrollbar } from '../../../utils/switchBodyScrollbar';

const RoutingModal = ({ children, isOpen, onClose }) => {
  const refModal = useRef(null);

  useEffect(() => {
    if (isOpen) {
      switchBodyScrollbar(true);
    } else {
      switchBodyScrollbar(false);
    }
  }, [isOpen]);

  const renderContent = () => {
    if (!isOpen) return null;
    return (
      <div
        ref={refModal}
        className={`modal modal_type_router ${isOpen && 'modal_active'}`}
        onMouseDown={onClose}>
        {children}
      </div>
    );
  };

  return createPortal(renderContent(), document.getElementById('modal_root'));
};

export default RoutingModal;
