import React, { useContext, useState } from 'react';
import './CardGallery.css';
import { useGoLinkToModal } from '../../../hooks/useGoLinkToModal';
import { StatusElement } from '../../StatusElement';
import AuthContext from '../../../context/AuthContext';

const CardGallery = ({ card, isStaff}) => {
  let { token } = useContext(AuthContext)
  const { goLinkToModal } = useGoLinkToModal();
  const {
    module: { title: moduleTitle, short_title_with_default },
    title,
    user: { first_name, last_name },
    screenshots: [{ screenshot } = {}] = [],
  } = card;
  
  

  return (
    <li
      className="card"
      onClick={() => goLinkToModal(`/works/${card.id}`, {staff:isStaff})}>
      {screenshot ? (
        <img
          className="card__img"
          src={screenshot}
        />
      ) : (
        <div className="card__img"></div>
      )}
      <div className="card__wrapper">
        <h3 className="card__title">{title || 'Название работы'}</h3>
        <p className="card__module">{short_title_with_default || moduleTitle || 'Название модуля'}</p>
        <p className="card__author">
          {first_name && last_name
            ? first_name + ' ' + last_name
            : first_name && !last_name
              ? first_name
              : !first_name && last_name
                ? last_name
                : !first_name && !last_name && 'Имя автора'}
        </p>
        {isStaff && <div className='listItem__div__cont_status'><StatusElement work={card} /></div>}
      </div>
    </li>
  );
};

export default CardGallery;
