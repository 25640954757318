import React from 'react'

export default class DoubleClick extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
        visibility: false,
    };

    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    this.setState(state => ({ 
        visibility: !state.visibility,
    }));
  }

  render() {
    if(this.state.visibility){
    return (
      <div>
          <div className='Moduletopic' style={this.state.style}>
            <label id="Aboutmodule">{this.props.about}</label>
         </div>
         <label id='Show_information' onClick={this.handleClick} > Скрыть информацию о модуле </label>
      </div>
    );
    
    } else{
        return(
         <div>
            <label id='Show_information' onClick={this.handleClick} > Показать информацию о модуле </label>
         </div>
        
        )
    }
}
}