import './../index.css';
import { useEffect, useRef } from 'react';
import { ReactComponent as CloseModalSVG } from '../../../images/icons/close-24.svg';

function PublishWorkModal({ isOpen, onClose, toAgree, toRefuse, question }) {
  return isOpen ? (
    <div onMouseDown={(e) => e.stopPropagation()}>
      <div
        className={`modal moda_type_question ${isOpen && 'modal_active'}`}
        onMouseDown={onClose}>
        <div
          className="modal__container modal__container_question"
          onMouseDown={(e) => e.stopPropagation()}>
          <div className="modal__header">
            <button
              type="button"
              className="modal__close-button"
              onClick={() => onClose()}>
              <CloseModalSVG />
            </button>
          </div>
          <div className="modal__wrapper">
            <h2 className="modal__question">Добавить работу в галерею?</h2>
            <p className="modal__question-detailed">
              Твою работу увидят другие ребята
            </p>
            <div className="modal__buttons modal__buttons_column">
              <button
                type="button"
                className="button_secondary button_secondary_blue"
                onClick={() => toAgree()}>
                Добавить
              </button>
              <button
                type="button"
                className="button_secondary button_secondary_white"
                onClick={() => toRefuse()}>
                Добавить позже
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

export default PublishWorkModal;
