import React, { useEffect, useState } from 'react';
import { ReactComponent as ArrowDropdownSVG } from '../../../images/icons/arrow_drop_down-24.svg';
import { ReactComponent as CheckedSVG } from '../../../images/icons/check-box-24.svg';
import { ReactComponent as UncheckedSVG } from '../../../images/icons/check-box-outline-blank-24.svg';
import { ReactComponent as CloseSVG } from '../../../images/icons/close-16-white.svg';
import CustomScrollbars from './CustomScrollbars';

import { useForm } from '../../../hooks/useForm';

import './FormGallery.css';

const FormGallery = ({ modules, setInputs }) => {
  const { values, handleChange, setValues } = useForm();
  const [dropdown, setDropdown] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };
  const [filtredModules, setFiltredModules] = useState(modules);

  // Закрыть по оверлею
  const handleOverlay = (e) => {
    if (e.target === e.currentTarget) {
      handleOpen();
    }
  };
  function checkedTags(id) {
    return dropdown.some((i) => i.id === id);
  }

  const dropdownListClick = (item) => {
    setDropdown((state) =>
      state.some((i) => i.id === item.id)
        ? state.filter((i) => i.id !== item.id)
        : state.concat(item),
    );
  };

  const filterModiles = (modules, value) => {
    if (value) {
      const filteredModules = modules.filter((i) =>
        [i.description, i.title, i.short_title_with_default]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      );
      return filteredModules;
    } else return modules;
  };

  // Фильтр модулей
  useEffect(() => {
    setFiltredModules(() => filterModiles(modules, values?.modules));
  }, [values?.modules]);

  // Фильтр модулей
  useEffect(() => {
    setInputs((state) => {
      return { ...state, modules: dropdown };
    });
  }, [dropdown]);

  const handleSubmitSearchField = (e) => {
    e.preventDefault();
    setInputs((state) => {
      return { ...state, searchValue: values.search || '' };
    });
  };
  return (
    <form
      className="gallery__search search"
      onSubmit={handleSubmitSearchField}>
      <div className="search__dropdown dropdown">
        <legend className="search__title">Фильтр</legend>
        <button
          className="dropdown__button"
          type="button"
          aria-expanded={open}
          aria-controls="dropdown__box"
          aria-haspopup="box"
          onClick={() => handleOpen()}>
          Выберите модуль
          <ArrowDropdownSVG />
        </button>
        {open ? (
          <div
            aria-hidden={open}
            aria-modal="true"
            id="dropdown__box"
            role="box"
            className="dropdown__box"
            onClick={(e) => {
              handleOverlay(e);
            }}>
            <div className="dropdown__search-field  search-field">
              <input
                type="search"
                name="modules"
                className="search-field__input"
                placeholder="Найти работу по модулю"
                onChange={handleChange}
                value={values?.modules || ''}
              />
              {values?.modules ? (
                <button
                  type="button"
                  className="search-field__clean"
                  aria-label="Очистить форму поиска"
                  onClick={() => setValues({ ...values, modules: '' })}
                />
              ) : null}
            </div>
            <CustomScrollbars
              className="dropdown__scroll-box"
              autoHeight
              autoHeightMin={48}
              autoHeightMax={192}>
              <ul className="dropdown__list">
                {filtredModules.length > 0 ? (
                  filtredModules?.map((module) => {
                    const { id } = module;
                    return (
                      <li
                        className="dropdown__item"
                        key={id}>
                        <input
                          className="dropdown__checkbox"
                          type="checkbox"
                          id={id}
                          checked={checkedTags(id)}
                          onChange={() => dropdownListClick(module)}
                        />
                        <label
                          htmlFor={id}
                          className="dropdown__label">
                          {checkedTags(id) ? <CheckedSVG /> : <UncheckedSVG />}
                          <span>{module.title}</span>
                        </label>
                      </li>
                    );
                  })
                ) : (
                  <li className="dropdown__item">
                    <span className="dropdown__label">Модуль не найден</span>
                  </li>
                )}
              </ul>
            </CustomScrollbars>
          </div>
        ) : null}
      </div>
      <div className="search__search-wrapper">
        <legend className="search__title">Поиск</legend>
        <div className="search__search-fieldr search-field ">
          <input
            className="search-field__input"
            name="search"
            onChange={handleChange}
            value={values?.search || ''}
            placeholder="Найти работу по названию"
          />
          {values?.search ? (
            <button
              type="button"
              className="search-field__clean"
              aria-label="Очистить форму поиска"
              onClick={(e) => {
                setValues({ ...values, search: '' });
                setInputs((state) => {
                  return { ...state, searchValue: '' };
                });
              }}
            />
          ) : null}
        </div>
        <button
          type="submit"
          className="submit__button_secondary button_secondary button_secondary_blue">
          Найти
        </button>
      </div>

      {dropdown.length > 0 ? (
        <ul className="search__chips">
          {dropdown.map((module) => {
            const { id } = module;
            return (
              <li
                className="search__chip"
                key={id}>
                {module.title}
                <button
                  className="search__chip-delete"
                  type="button"
                  onClick={() => dropdownListClick(module)}>
                  <CloseSVG />
                </button>
              </li>
            );
          })}
          <button
            type="button"
            className="search__delete-button"
            onClick={() => setDropdown([])}>
            Очистить все
            <CloseSVG />
          </button>
        </ul>
      ) : null}
    </form>
  );
};

export default FormGallery;
