import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../utils/Api';

export const getModules = createAsyncThunk(
  'modules/getModules',
  async (_, { rejectWithValue }) => {
    try {
      const res = await api.getModules();
      // потом убрать
      // console.log('getModules() => res', res);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createModule = createAsyncThunk(
  'modules/createModule',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await api.createModule(data);
      // потом убрать
      // console.log('createModule(data) => res', data, res);
      dispatch(getModules());
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getModuleById = createAsyncThunk(
  'modules/getModuleById',
  async (id, { rejectWithValue }) => {
    try {
      const res = await api.getModuleById(id);
      // потом убрать
      // console.log('getModuleById(id) => res', id, res);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const putModuleById = createAsyncThunk(
  'modules/putModuleById',
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const res = await api.putModuleById(id, data);
      // потом убрать
      // console.log('putModuleById(id, data) => res', id, data, res);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const patchModuleById = createAsyncThunk(
  'modules/patchModuleById',
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const res = await api.patchModuleById(id, data);
      // потом убрать
      // console.log('patchModuleById(id, data) => res', id, data, res);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteModuleById = createAsyncThunk(
  'modules/deleteModuleById',
  async (id, { rejectWithValue }) => {
    try {
      const res = await api.deleteModuleById(id);
      // потом убрать
      // console.log('deleteModuleById(id) => res', id, res);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
