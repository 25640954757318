import React, { useState, useEffect, useContext } from 'react'
import AuthContext from '../context/AuthContext'
import avatar_0 from './../pages/images/avatars/avatar_1.svg'
import avatar_1 from './../pages/images/avatars/avatar_2.svg'
import avatar_2 from './../pages/images/avatars/avatar_3.svg'
import avatar_3 from './../pages/images/avatars/avatar_4.svg'
import avatar_4 from './../pages/images/avatars/avatar_5.svg'
import avatar_5 from './../pages/images/avatars/avatar_6.svg'
import avatar_6 from './../pages/images/avatars/avatar_7.svg'
import avatar_7 from './../pages/images/avatars/avatar_8.svg'
import avatar_8 from './../pages/images/avatars/avatar_9.svg'
import changeBtn from './../pages/images/change_white.svg'

const images = [avatar_0, avatar_1, avatar_2, avatar_3, avatar_4, avatar_5, avatar_6, avatar_7, avatar_8]
export default function RandomWelcomePicture({id, user, token, isAccount}) {
    const [currentImageIndex, setCurrentImageIndex] = useState(user.avatar)//тут хранится константная фотография
    const [isLoading, setLoading] = useState(false)
    const changeImage = () => {
      setCurrentImageIndex((currentImageIndex + 1) % 9)
      updateUser()
    }

    let {logoutUser} = useContext(AuthContext)

    async function updateUser() {
        if (isLoading) {
            alert('Подождите, идет загрузка')
            return
        }
        setLoading(true)
        let form_data = new FormData()
        form_data.append('user', id)
        form_data.append('birth_date', user.birth_date)
        form_data.append('avatar', currentImageIndex)
        user.modules.map(item => form_data.append('modules', item.id))
        let response = await fetch(`https://kiberonevl.ru/api/extra/${user.id}/`, {
            method: 'PUT',
            headers:{
                'Authorization':'Bearer ' + token,
            },
            body: form_data
        })
        if (response.ok) {
            setLoading(false)
        } else if (response.status === 401) {
            alert('Токен авторизации устарел. Пожалуйста, войдите в аккаунт заново.')
            setLoading(false)
            logoutUser()
        } else {
            alert('Произошла ошибка')
            setLoading(false)
        }
    }

    return (
        <div>
            <img id='avatar' src={images[currentImageIndex]} alt='Аватарка' />
            {isAccount && <img id='change_button' onClick={changeImage} src={changeBtn}/>}
        </div>
    
    )
  }