import { getToken } from './tokenStorage';

class Api {
  constructor(options) {
    this._baseUrl = options.baseUrl;
    this._headers = options.headers;
    this._credentials = options.credentials;
  }

  _checkResponse = async (res) => {
    if (res.ok) {
      if (res.status === 204) {
        return res;
      }
      const data = await res.json();
      return data;
    }
    if (res.status === 404) {
      throw res;
    }
    const err = await res.json();
    throw err;
  };

  // Делаем запрос на сервер
  _makeRequest = async (url, method, data, formData, params) => {
    const token = getToken();
    // console.log('_makeRequest=>', token);
    if (token) {
      this._headers.Authorization = `Bearer ${token.access}`;
    }
    const parameters = params || '';
    const config = {
      method,
      headers: this._headers,
    };

    if (data !== undefined) {
      //console.log('_makeRequest => body ', data);
      config.body = JSON.stringify(data);
      //console.log('_makeRequest=> url, config.body', config.body);
    }
    if (formData !== undefined) {
      //console.log('_makeRequest => body ', formData);
      config.body = formData;
    }

    const res = await fetch(`${this._baseUrl}${url}${parameters}`, config);

    return this._checkResponse(res);
  };

  // Extra
  // Добавить Extra
  createExtra = (formData) =>
    this._makeRequest('/extra/create/', 'POST', undefined, formData);
  // Получить Extra по ID
  getExtraById = (id) => this._makeRequest(`/extra/${id}/`, 'GET');
  // изменить Extra полностью
  putExtraById = (id, formData) =>
    this._makeRequest(`/extra/${id}/`, 'PUT', undefined, formData);
  // изменить Extra частично
  patchExtraById = (id, formData) =>
    this._makeRequest(`/extra/${id}/`, 'PATCH', undefined, formData);
  // Удалить Extra по ID
  deleteExtraById = (id) => this._makeRequest(`/extra/${id}/`, 'DELETE');

  // modules
  // Получить модули
  getModules = () => this._makeRequest('/modules/', 'GET');
  // Добавить модуль
  createModule = (data) => this._makeRequest('/modules/create/', 'POST', data);
  // Получить модуль по ID
  getModuleById = (id) => this._makeRequest(`/modules/${id}/`, 'GET');
  // изменить модуль полностью
  putModuleById = (id, data) =>
    this._makeRequest(`/modules/${id}/`, 'PUT', data);
  // изменить модуль частично
  patchModuleById = (id, data) =>
    this._makeRequest(`/modules/${id}/`, 'PATCH', data);
  // Удалить модуль по ID
  deleteModuleById = (id) => this._makeRequest(`/modules/${id}/`, 'DELETE');

  // Notes
  // Получить заметки
  getNotes = () => this._makeRequest('/notes/', 'GET');
  // Добавить заметку
  createNote = (data) => this._makeRequest('/notes/create/', 'POST', data);
  // Получить заметку по ID
  getNoteById = (id) => this._makeRequest(`/notes/${id}/`, 'GET');
  // изменить заметку полностью
  putNoteById = (id, data) => this._makeRequest(`/notes/${id}/`, 'PUT', data);
  // изменить заметку частично
  patchNoteById = (id, data) =>
    this._makeRequest(`/notes/${id}/`, 'PATCH', data);
  // Удалить заметку по ID
  deleteNoteById = (id) => this._makeRequest(`/notes/${id}/`, 'DELETE');

  //  Screenshot
  // Добавить заметку
  createScreenshot = (formData) =>
    this._makeRequest('/screenshots/create/', 'POST', undefined, formData);
  // Получить заметку по ID
  getScreenshotById = (id) => this._makeRequest(`/screenshots/${id}/`, 'GET');
  // изменить заметку полностью
  putScreenshotById = (id, formData) =>
    this._makeRequest(`/screenshots/${id}/`, 'PUT', undefined, formData);
  // изменить заметку частично
  patchScreenshotById = (id, formData) =>
    this._makeRequest(`/screenshots/${id}/`, 'PATCH', undefined, formData);
  // Удалить заметку по ID
  deleteScreenshotById = (id) =>
    this._makeRequest(`/screenshots/${id}/`, 'DELETE');

  // Token
  createToken = (data) => this._makeRequest('/token/', 'POST', data);
  // Token Refresh
  createTokenRefresh = (data) =>
    this._makeRequest('/token/refresh/', 'POST', data);

  // Users
  // Получить пользователей
  getUsers = () => this._makeRequest('/users/', 'GET');
  // Добавить пользователя
  createUser = (data) => this._makeRequest('/users/create/', 'POST', data);
  // Получить пользователя по ID
  getUserById = (id) => this._makeRequest(`/users/${id}/`, 'GET');
  // изменить пользователя полностью
  putUserById = (id, data) => this._makeRequest(`/users/${id}/`, 'PUT', data);
  // изменить пользователя частично
  patchUserById = (id, data) =>
    this._makeRequest(`/users/${id}/`, 'PATCH', data);
  // Удалить пользователя по ID
  deleteUserById = (id) => this._makeRequest(`/users/${id}/`, 'DELETE');

  // works
  // Получить пользователей
  getWorks = () => this._makeRequest('/works/', 'GET');
  // Добавить Extra
  createWork = (formData) =>
    this._makeRequest('/works/create/', 'POST', undefined, formData);
  // Получить works по ID
  getWorkById = (id) => this._makeRequest(`/works/${id}/`, 'GET');
  // изменить works полностью
  putWorkById = (id, data) => this._makeRequest(`/works/${id}/`, 'PUT', data);
  // изменить works частично
  patchWorkById = (id, data) =>
    this._makeRequest(`/works/${id}/`, 'PATCH', data);
  // Удалить works по ID
  deleteWorkById = (id) => this._makeRequest(`/works/${id}/`, 'DELETE');
}

const config = {
  baseUrl: 'https://kiberonevl.ru/api',
  headers: {
    'Content-Type': 'application/json',
  },
};

export const api = new Api(config);
