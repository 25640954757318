import "./PublishModal.css";
import CloseModalSVG from '../../../../images/icons/close-24.svg';
import InputWithLabel from "../../../../components/InputWithLabel/InputWithLabel";
import calendar from "../../../../images/icons/calendar_month_24.svg"
import { useRef, useState, useEffect } from "react";
import SubmitButton from "../../../../components/SubmitButton/SubmitButton";
import { getToken } from "../../../../utils/tokenStorage";
import AuthContext from "../../../../context/AuthContext";
import { useContext } from "react";
import LoadingScreen from "../../../../components/Loader/Loader";

function ModalAddStudent({ isOpen, onClose, users, question, oldDataUser }) {
  let { user } = useContext(AuthContext)
  let { token } = useContext(AuthContext)
  let [passwordChecker, setPasswordChecker] = useState('')
  let [isLoading, setLoading] = useState(false)
  let [password, setPassword] = useState('')
  const dateInputRef = useRef(null);
  let {logoutUser} = useContext(AuthContext)

  const [validInputName, setvalidInputName] = useState("200")
  const [validInputLastName, setvalidInputLastName] = useState("200")
  const [validInputBirthday, setvalidInputBirthday] = useState("200")
  const [validInputPassword, setvalidInputPassword] = useState("200")
  const [validInputEmail, setvalidInputEmail] = useState("200")

  const openDateInput = () => {
    dateInputRef.current.click();
  };

  const [newUser, setNewUser] = useState({
    id: '',
    extra_id: '',
    last_name: '',
    first_name: '',
    email: '',
    password: '',
    birth_date: '',
  })

  function generatePassword(length) {
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let password = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    setPassword(password)
    setNewUser({ ...newUser, password: password })
  }



  useEffect(() => {
    if (oldDataUser) {
      setNewUser({
        id: oldDataUser.id || '',
        extra_id: oldDataUser.extra?.id || '',
        last_name: oldDataUser.last_name || '',
        first_name: oldDataUser.first_name || '',
        email: oldDataUser.email || '',
        password: '', // You may want to handle password separately if you want to keep it empty
        birth_date: formatDateForInput(oldDataUser?.extra?.birth_date) || '',
      });
    }
  }, [oldDataUser]);

  function isPasswordMatch() {
    return newUser.password === passwordChecker
  }

  function handleLastnameChange(event) {
    setNewUser({ ...newUser, last_name: event.target.value })
  }

  function handleFirstnameChange(event) {
    setNewUser({ ...newUser, first_name: event.target.value })
    if(event.target.value){
      setvalidInputName("200")
    }
  }

  function handleEmailChange(event) {
    setNewUser({ ...newUser, email: event.target.value })
    if(event.target.value){
      setvalidInputLastName("200")
    }
  }

  function handlePasswordChange(event) {
    setNewUser({ ...newUser, password: event.target.value })
    if(event.target.value){
      setvalidInputPassword("200")
    }
  }

  function handleBirthDateChange(event) {
    setNewUser({ ...newUser, birth_date: event.target.value })
  }

  async function createUser() {
    if(!newUser.first_name){
      setvalidInputName("Введите имя")
      return
    }
    else if(!newUser.last_name){
      setvalidInputLastName("Введите фамилию")
      return
    }
    else if(!newUser.birth_date){
      setvalidInputBirthday("Введите дату рождения")
      return
    }
    else if(!newUser.password){
      setvalidInputPassword("Введите пароль")
      return
    }
    else if(!newUser.email){
      setvalidInputEmail("Введите почту")
      return
    }

    if (isLoading) {
      alert('Подождите, идет загрузка')
      return
    }
    setLoading(true)
    let response = await fetch(`https://kiberonevl.ru/api/users/create/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify({
        'username': newUser.email.substring(0, newUser.email.lastIndexOf('@')),
        'email': newUser.email, 'first_name': newUser.first_name, 'last_name': newUser.last_name,
        'password': newUser.password
      })
    })
    if (response.ok) {
      let data = await response.json()
      let form_data_extra = new FormData()
      form_data_extra.append('user', data.id)
      form_data_extra.append('birth_date', newUser.birth_date)
      let ss_response = await fetch(`https://kiberonevl.ru/api/extra/create/`, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + token,
        },
        body: form_data_extra
      })
      if (ss_response.ok) {
        window.location.reload();
        alert('Все прошло успешно')
        setLoading(false)
      } else {
        alert('Что-то пошло не так!')
        setLoading(false)
      }

    } else if (response.status === 400) {
      alert('Введены некорректные данные или пользователь с такой почтой уже существует!')
      setLoading(false)
    } else if (response.status === 401) {
      logoutUser();
      alert('Токен авторизации устарел. Пожалуйста, войдите в аккаунт заново.')
      setLoading(false)
    } else {
      alert('Что-то пошло не так!')
      setLoading(false)
    }
  }
  // async function updateUser() {
  //   if (isLoading) {
  //     alert('Подождите, идет загрузка')
  //     return
  //   }
  //   setLoading(true)
  //   let userBody = {
  //     'username': newUser.email.substring(0, newUser.email.lastIndexOf('@')),
  //     'email': newUser.email, 'first_name': newUser.first_name, 'last_name': newUser.last_name
  //   }
  //   if (newUser?.password !== '' && isPasswordMatch) userBody.password = newUser?.password
  //   let response = await fetch(`https://kiberonevl.ru/api/users/${newUser.id}/`, {
  //     method: 'PATCH',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': 'Bearer ' + token,
  //     },
  //     body: JSON.stringify(userBody)
  //   })
  //   if (response.ok) {
  //     let data = await response.json()
  //     console.log('DATA:', data, response.status)
  //     let form_data_extra = new FormData()
  //     form_data_extra.append('user', newUser.id)
  //     form_data_extra.append('birth_date', newUser.birth_date)
  //     console.log(...form_data_extra)
  //     let extra_response = await fetch(`https://kiberonevl.ru/api/extra/${newUser.id}/`, {
  //       method: 'PUT',
  //       headers: {
  //         'Authorization': 'Bearer ' + token,
  //       },
  //       body: form_data_extra
  //     })

  //     if (extra_response.ok) {
  //       window.location.reload();
  //       alert('Все прошло успешно extra')
  //       let newUsers = users
  //       for (let i = 0; i < newUsers.length; i++) {
  //         if (data.id === newUsers[i].id) newUsers[i] = data
  //       }
  //       //setUsers(newUsers)
  //       setLoading(false)
  //     } else {
  //       alert('Что-то пошло не так!')
  //       setLoading(false)
  //     }
  //   } else if (response.status === 400) {
  //     alert('Введены некорректные данные!')
  //     setLoading(false)
  //   } else if (response.status === 401) {
  //     alert('Токен авторизации устарел. Пожалуйста, войдите в аккаунт заново.')
  //     setLoading(false)
  //     //logoutUser()
  //   } else {
  //     alert('Что-то пошло не так!')
  //     setLoading(false)
  //   }

  // }

  async function EditUser() {
    if (isLoading) {
      alert('Подождите, идет загрузка')
      return
    }
    setLoading(true)
    let userBody = {
      'username': newUser.email.substring(0, newUser.email.lastIndexOf('@')),
      'email': newUser.email, 'first_name': newUser.first_name, 'last_name': newUser.last_name
    }
    if (newUser?.password !== '' && isPasswordMatch) userBody.password = newUser?.password
    let response = await fetch(`https://kiberonevl.ru/api/users/${newUser.id}/`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify(userBody),
    })
    if (response.ok) {
      alert("LF!")
      let data = await response.json()
      // console.log('DATA:', data, response.status)
      let form_data_extra = new FormData()
      form_data_extra.append('user', newUser.id)
      form_data_extra.append('birth_date', newUser.birth_date)
      // console.log(...form_data_extra)
      let extra_response = await fetch(`https://kiberonevl.ru/api/extra/${newUser.extra_id}/`, {
        method: 'PUT',
        headers: {
          'Authorization': 'Bearer ' + token,
        },
        body: form_data_extra
      })
      if (extra_response.ok) {
        alert('Все прошло успешно')

        let newUsers = users
        for (let i = 0; i < newUsers.length; i++) {
          if (data.id === newUsers[i].id) newUsers[i] = data
        }
        //setUsers(newUsers)
        setLoading(false)
        window.location.reload();
      } else {
        alert('Что-то пошло не так!')
        setLoading(false)
      }
    } else if (response.status === 400) {
      alert('Введены некорректные данные!')
      setLoading(false)
    } else if (response.status === 401) {
      logoutUser();
      alert('Токен авторизации устарел. Пожалуйста, войдите в аккаунт заново.')
      setLoading(false)
      //logoutUser()
    } else {
      alert('Что-то пошло не так!')
      setLoading(false)
    }
  }

  function formatDateForInput(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return ""; // Invalid date, return an empty string
    }

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  return isOpen ? (
    <div onMouseDown={(e) => e.stopPropagation()}>
      <div
        className={`modal moda_type_question ${isOpen && 'modal_active'}`}
        onMouseDown={onClose}>
        <div
          className="modal__container modal__container_question_add_student"
          onMouseDown={(e) => e.stopPropagation()}>
          <div className="modal__header">
            <button
              type="button"
              className="modal__close-button"
              onClick={() => onClose()}>
              <img className={"modal__close_img"} src={CloseModalSVG} />
            </button>
          </div>
          <div className="modal__wrapper">
            <h2 className="modal__question_add_student">
              {question}
            </h2>
            <div className="cont_modal-add-student">
              <InputWithLabel width={412} className="big" textLabel={"Имя"} placeholder={"Введите имя ученика"} errorStatus={validInputName} onChange={handleFirstnameChange} defaultValue={oldDataUser?.first_name || ""} />
              <InputWithLabel width={412} className="big" textLabel={"Фамилия"} placeholder={"Введите фамилию ученика"} errorStatus={validInputLastName} onChange={handleLastnameChange} defaultValue={oldDataUser?.last_name || ""} />
              <InputWithLabel width={412} className="big" textLabel={"Email"} placeholder={"Почтовый адрес ученика"} errorStatus={validInputEmail} onChange={handleEmailChange} defaultValue={oldDataUser?.email || ""} />
              <label className="label_text">
                <div className="label_text_big_font">Дата рождения</div>
                <input
                  className="modal__calendar_input_calendar "

                  type="date"
                  ref={dateInputRef}
                  onChange={handleBirthDateChange}
                  defaultValue={formatDateForInput(oldDataUser?.extra?.birth_date) || ""}
                />
              </label>
              <InputWithLabel width={412} className="big" textLabel={"Пароль"} placeholder={"Введите пароль аккаунта ученика "} errorStatus={validInputPassword} onChange={handlePasswordChange} defaultValue={password} />
              <button className="modal__generate_password" onClick={() => { generatePassword(10) }}>Сгенерировать пароль</button>
            </div>
            <SubmitButton className={"modal__wrapper_sub-btn"} content={"Сохранить"} onClick={oldDataUser ? EditUser : createUser} />
          </div>
        </div>
      </div>
    </div>
  ) : <LoadingScreen />;
}
export default ModalAddStudent