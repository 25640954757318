import React, { useState } from 'react';
import './ModuleBar.css';

export default function ModulesBar(onSelected) {
  const [selected, setSelected] = useState('all');

  const handleRadioChange = (id) => {
    setSelected(id)
    onSelected.onSelected(id);
  };

  return (
    <div className='container-ModuleBar'>
      <label htmlFor="all" className={selected === 'all' ? 'custom-radio checked' : 'custom-radio'}>Все работы
        <input
          type="radio"
          name="r"
          id="all"
          onChange={() => handleRadioChange('all')}
        />
      </label>
      <label htmlFor="verified" className={selected === 'verified' ? 'custom-radio checked' : 'custom-radio'}>Проверенные работы
        <input
          type="radio"
          name="r"
          id="verified"
          onChange={() => handleRadioChange('verified')}
        />
      </label>
      <label htmlFor="notPublic" className={selected === 'notPublic' ? 'custom-radio checked' : 'custom-radio'}>Не опубликованные
        <input
          type="radio"
          name="r"
          id="notPublic"
          onChange={() => handleRadioChange('notPublic')}
        />
      </label>
      <label htmlFor="onVerification" className={selected === 'onVerification' ? 'custom-radio checked' : 'custom-radio'}>На проверке
        <input
          type="radio"
          name="r"
          id="onVerification"
          onChange={() => handleRadioChange('onVerification')}
        />
      </label>
      <label htmlFor="forСorrection" className={selected === 'forСorrection' ? 'custom-radio checked' : 'custom-radio'}>На исправление
        <input
          type="radio"
          name="r"
          id="forСorrection"
          onChange={() => handleRadioChange('forСorrection')}
        />
      </label>
    </div>
  );
}
