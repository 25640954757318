import React, { useEffect, useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  FreeMode,
  Navigation,
  Thumbs,
  EffectCreative,
  Controller,
  Pagination,
  Keyboard,
} from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import 'swiper/css/pagination';

import './styles.css';
import { ReactComponent as NextArrowSVG } from '../../../images/icons/arrow_forward_ios.svg';
import { ReactComponent as PrevArrowSVG } from '../../../images/icons/arrow_back_ios_new.svg';
import { ReactComponent as CloseCircleSvg } from '../../../images/icons/close-circle.svg';
import OtherModal from '../../modal/OtherModal';

export default function ImagesSwiper({ slides }) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [firstSwiper, setFirstSwiper] = useState(null);
  const [secondSwiper, setSecondSwiper] = useState(null);

  const [isOpen, setOpen] = useState(false);
  const decorationSpeed = 1000;
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  // указываем `useEffect` для обработчика `Escape`
  useEffect(() => {
    if (!isOpen) return;
    function handleEscapeKey(e) {
      if (e.code === 'Escape') {
        setOpen(false);
      }
    }
    document.addEventListener('keydown', handleEscapeKey);
    return () => document.removeEventListener('keydown', handleEscapeKey);
  }, [isOpen, setOpen]);

  return (
    <>
      <div className="images__wrapper">
        <Swiper
          rewind={true}
          className="images__swiper"
          spaceBetween={20}
          speed={decorationSpeed}
          thumbs={{
            swiper:
              thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
          }}
          modules={[Navigation, Thumbs, Controller]}
          controller={{ control: secondSwiper }}
          watchSlidesProgress
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onSwiper={(swiper) => {
            setFirstSwiper(swiper);
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;

            swiper.navigation.destroy();
            swiper.navigation.init();
            swiper.navigation.update();
          }}>
          {slides?.map((item) => {
            return (
              <SwiperSlide key={item.id}>
                <img
                  src={item.screenshot}
                  alt="Скриншот"
                  className="images__image"
                  onClick={() => {
                    setOpen((state) => !state);
                  }}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <button
          ref={navigationPrevRef}
          className="images__btn images__btn_prev swiper-button-prev">
          <PrevArrowSVG />
        </button>
        <button
          ref={navigationNextRef}
          className="images__btn images__btn_next swiper-button-next">
          <NextArrowSVG />
        </button>
      </div>
      <Swiper
        className="images__nav-swiper"
        onSwiper={setThumbsSwiper}
        rewind={true}
        spaceBetween={20}
        slidesPerView={3}
        speed={decorationSpeed}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}>
        {slides?.map((item) => {
          return (
            <SwiperSlide key={item.id}>
              <img
                src={item.screenshot}
                alt="Скриншот"
                className="images__image"
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <OtherModal
        isOpen={isOpen}
        onClose={() => {
          setOpen((state) => !state);
        }}>
        <div
          className="images__modal"
          onClick={(e) => e.stopPropagation()}>
          <button
            type="button"
            className="modal__close"
            onClick={() => {
              setOpen((state) => !state);
            }}>
            <CloseCircleSvg />
          </button>
          <Swiper
            className="modal__swiper"
            style={{
              '--swiper-pagination-bullet-inactive-opacity': '1',
              '--swiper-pagination-bullet-opacity': '1',
            }}
            modules={[
              EffectCreative,
              Navigation,
              Controller,
              Pagination,
              Keyboard,
            ]}
            // loop
            effect="creative"
            creativeEffect={{
              prev: {
                shadow: true,
                translate: ['-120%', 0, -100],
              },
              next: {
                shadow: true,
                translate: ['120%', 0, -100],
              },
            }}
            rewind={true}
            onSwiper={setSecondSwiper}
            controller={{ control: firstSwiper }}
            slidesPerView={1.2}
            slidesPerGroupSkip={1}
            watchSlidesProgress
            grabCursor
            centeredSlides
            speed={decorationSpeed}
            keyboard={{
              enabled: true,
            }}
            navigation
            pagination={{ clickable: true }}
            spaceBetween={20}>
            {slides?.map((item) => {
              return (
                <SwiperSlide key={item.id}>
                  <img
                    src={item.screenshot}
                    alt="Скриншот"
                    className="images__image"
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </OtherModal>
    </>
  );
}
