import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../context/AuthContext';
import Password from './Password/Password';
import InputWithLabel from '../../components/InputWithLabel/InputWithLabel';
import ForgetFata from './ForgetData/ForgetData';
import './Entrance.css';
import SubmsitButton from '../../components/SubmitButton/SubmitButton';
import { ThreeDots } from 'react-loader-spinner';
import MobileDevice from '../../components/MobileDevice/MobileDevice';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

function ErrorMessage({ error }) {
  if (error === '200' || error === '') {
    return null;
  }
  if (error === 401) {
    return <div className="error_text">Неправильный логин или пароль</div>;
  } else {
    return (
      <div className="error_text">
        {error} Неизветсная ошибка, перезагрузите страницу
      </div>
    );
  }
}

export default function Entrance() {
  let { loginUser, errorInput, tryLogin,setErrorInput } = useContext(AuthContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [isLoading,setIsLoading] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    document.title = 'Вход';
  }, []);

  useEffect(()=>{
    if(localStorage.getItem("accessToken")){
      navigate('/account')
    }
  },[])

  const handleLogin = (e) => {
    setIsLoading(true);
    e.preventDefault();
    return loginUser(e);
  };
  
  useEffect(()=>{
      setIsLoading(false);
  },[errorInput,tryLogin])

  function removeError(){
    setErrorInput("200")
  }

  return isMobile?<MobileDevice/>:(
    <form
    
      id="login_form"
      onSubmit={handleLogin}>
      <h2>Вход в учетную запись</h2>
      <br />
      <div onClick={removeError}>
      <InputWithLabel
        idInput={'Email_input'}
        textLabel={'Логин'}
        placeholder={'Введите логин'}
        errorStatus={errorInput}
      />
      <br/>
      <Password errorStatus={errorInput} />
      <ErrorMessage error={errorInput} />
      </div>
      <div className="footer">
        
        <a onClick={openModal}>Забыли логин или пароль?</a>
        {isModalOpen && <ForgetFata closeForgetData={closeModal} />}
      </div>
      <SubmsitButton
        onClick={() => {
          setIsLoading(true);
        }}
        content={isLoading?<ThreeDots
          height="40"
          width="35"
          radius="6"
          color="#FEFEFE"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />:"войти"}
        w={340}
        h={40}
      />
    </form>
  );
}
