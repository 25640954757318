import { configureStore } from '@reduxjs/toolkit';
import worksReducer from './works/worksSlise';
import modulesReducer from './modules/modulesSlise';

export default configureStore({
  reducer: {
    works: worksReducer,
    modules: modulesReducer,
  },
});

export const workSelectors = (state) => state.works;
export const moduleSelectors = (state) => state.modules;

export { getModules } from './modules/actions';
export { getWorks } from './works/actions';
