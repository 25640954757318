import { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const PrivateRoute = ({context}) => {
    let {token} = useContext(context)
    return(
        token ? <Outlet/> : <Navigate to="/"/>
    )
}

export default PrivateRoute
