import './PublishModal.css';
import CloseModalSVG  from '../../images/icons/close-24.svg';

function PublishModal({ isOpen, onClose, toAgree, toRefuse, question }) {
  return isOpen ? (
    <div onMouseDown={(e) => e.stopPropagation()}>
      <div
        className={`modal moda_type_question ${isOpen && 'modal_active'}`}
        onMouseDown={onClose}>
        <div
          className="modal__container modal__container_question"
          onMouseDown={(e) => e.stopPropagation()}>
          <div className="modal__header">
            <button
              type="button"
              className="modal__close-button"
              onClick={() => onClose()}>
              <img  className={"modal__close_img"}src={CloseModalSVG}/>
            </button>
          </div>
          <div className="modal__wrapper">
            <h2 className="modal__question">
              {question}
            </h2>
            <br/>
            Твою работу увидят другие ребята
            <br/>
            <div className="modal__button_column">
              <button
                type="button"
                className="button_secondary button_secondary_white"
                onClick={toRefuse}>
                Добавить позже
              </button>
              <br/>
              <button
                type="button"
                className="button_secondary button_secondary"
                onClick={toAgree}>
                Добавить
              </button>
              <br/>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

export default PublishModal;
