import React, { useState } from 'react';
import eyeOff from '../../images/eye_closed.svg';
import eye from '../../images/eye_open.svg';
import './Password.css';

export default function Password({errorStatus, onChange}) {
  const inputClass = (errorStatus === 401 ) ? 'login_input error' : 'login_input ';
  const [password, setPassword] = useState('');
  const [type, setType] = useState('password');
  const [icon, setIcon] = useState(eyeOff);

  const handleToggle = () => {
    if (type === 'password') {
      setIcon(eye);
      setType('text');
    } else {
      setIcon(eyeOff);
      setType('password');
    }
  }

  return (
    <div className='label_text inputWithIcon'>
      Пароль
      <input
        className={inputClass}
        type={type}
        name='password'
        onShange={onChange}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        autoComplete='current-password'
        placeholder='Введите пароль'
      />
      <span onClick={handleToggle} className='eye_icon'>
        <img src={icon} alt='eye-icon' />
      </span>
    </div>
  );
}
