import './../index.css';
import { ReactComponent as CloseModalSVG } from '../../../images/icons/close-24.svg';
import { useRef } from 'react';

function AgreeChangePhotoModal({ isOpen, onClose, toAgree }) {
  const fileInputRef = useRef(null);
  const handleInputClick = () => {
    // Вызывает метод click на input, эмулируя клик на input
    fileInputRef.current.click();
};
  const handleFile=(e)=>{
      toAgree(e.target.files[0])
  }
  return isOpen ? (
    <div onMouseDown={(e) => e.stopPropagation()}>
      <div
        className={`modal moda_type_question modal_active`}
        onMouseDown={onClose}>
        <div
          className="modal__container modal__container_question"
          onMouseDown={(e) => e.stopPropagation()}>
          <div className="modal__header">
            <button
              type="button"
              className="modal__close-button"
              onClick={() => onClose()}>
              <CloseModalSVG />
            </button>
          </div>
          <div className="modal__wrapper">
            <h2 className="modal__question">Изменить изображение профиля</h2>
            
            <div className="modal__buttons modal__buttons_column">
              <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                style={{display:"none"}}
                onChange={(e)=>handleFile(e)}/>
                <div className="button_secondary button_secondary_blue" onClick={handleInputClick}>Загрузить фото</div>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

export default AgreeChangePhotoModal;
