import React, { createContext, useState, useEffect } from 'react';
import { Routes, useNavigate, Route } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import Account from '../pages/Account';
const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const [isLogin, setLogin] = useState(
    localStorage.getItem('accessToken') ? true : false,
  );
  const [access, setAccess] = useState(localStorage.getItem('accessToken'));
  const [refresh, setRefresh] = useState(localStorage.getItem('refreshToken'));
  const [isLoading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [errorInput, setErrorInput] = useState('200')
  const [tryLogin,setTryLogin] = useState(0)

  const navigate = useNavigate();


  let loginUser = async (e) => {
    e.preventDefault();
    if (isLoading) {
      return;
    }
    setLoading(true);
    let response = await fetch('https://kiberonevl.ru/api/token/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: e.target.Email_input.value,
        password: e.target.password.value,
      }),
    });
    let data = await response.json();
    if (response.ok) {
      navigate('/gallery');
      setLoading(false);
      localStorage.setItem('accessToken', data.access);
      setAccess(data.access);
      localStorage.setItem('refreshToken', data.refresh);
      setRefresh(data.refresh);

    } else if (response.status === 400) {
      alert('Некорректные данные, попробуйте еще раз!');
      setErrorInput(response.status)
      setLoading(false);
    } else if (response.status === 401) {
      setErrorInput(response.status)
      setLoading(false);
    } else {
      alert('Что-то пошло не так!');
      setLoading(false);
    }
    setTryLogin(tryLogin+1)
  };

  let logoutUser = () => {
    setAccess(null);
    localStorage.removeItem('accessToken');
    setRefresh(null);
    localStorage.removeItem('refreshToken');
    setUser(null);
    setLogin(true);
    navigate('/');
  };

  let contextData = {
    user: user,
    token: access,
    loginUser: loginUser,
    logoutUser: logoutUser,
    errorInput: errorInput,
    setErrorInput:setErrorInput,
    tryLogin: tryLogin
  };

  useEffect(() => {
    let getUser = async () => {
      setLoading(true);
      let token = access;
      let user_id = jwt_decode(token).user_id;
      let response = await fetch(
        `https://kiberonevl.ru/api/users/${user_id}/`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        },
      );
      let data = await response.json();
      if (response.ok) {
        setUser(data);
        if (isLogin) {
          setLogin(true);
          setLoading(false);
        
        }
      } else {
        if (isLogin) {
          alert('Something went wrong!');
        }
        logoutUser();
        setLoading(false);
      }
    };
    let updateToken = async () => {
      let response = await fetch(
        'https://kiberonevl.ru/api/token/refresh/',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ refresh: refresh }),
        },
      );
      let data = await response.json();

      if (response.ok) {
        
        localStorage.setItem('accessToken', data.access);
        setAccess(data.access);
        localStorage.setItem('refreshToken', data.refresh);
        
        setRefresh(data.refresh);
        setLoading(false);
        setLogin(true);
      } else if (response.status === 401) {
        alert(
          'Токен авторизации устарел. Пожалуйста, войдите в аккаунт заново.',
        );
        setLoading(false);
        logoutUser();
      } else {
        setLoading(false);
        logoutUser();
      }
    };

    if (access) {
      getUser();
    }

    let twentyMin = 1000 * 60 * 20;
    let interval = setInterval(() => {
      if (access) {
        updateToken();
      }
    }, twentyMin);
    return () => clearInterval(interval);
  }, [access]);
  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  );
};
