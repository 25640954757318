import { useContext } from "react";
import { getToken } from "../../utils/tokenStorage";
import AuthContext from "../../context/AuthContext";

export const useHandleSubmitAddWork =  () => {

    async function HandleSubmitAddWork(e, work, user, setLoading, onClose) {
        let { logoutUser } = useContext(AuthContext)

        if (e) {
            e.preventDefault();
        } else {
            console.error("Invalid event object:", e);
        }
        if (work.file) {
            setLoading(true)
            let form_data = new FormData()
            form_data.append('title', work.title)
            form_data.append('module', work.module?.id)
            form_data.append('file', work.file, work.file.name)
            form_data.append('comment', work.comment)
            form_data.append('is_public', work.is_public)
            form_data.append('user', user.id)
            let response = await fetch(`https://kiberonevl.ru/api/works/create/`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + getToken().access
                },
                body: form_data
            })
            if (response.ok) {
                let data = await response.json()
                for (let ss of work.screenshots) {
                    let form_data_screenshots = new FormData()
                    form_data_screenshots.append('work', data.id)
                    form_data_screenshots.append('screenshot', ss, ss.name)
                    let ss_response = await fetch(`https://kiberonevl.ru/api/screenshots/create/`, {
                        method: 'POST',
                        headers: {
                            'Authorization': 'Bearer ' + getToken().access
                        },
                        body: form_data_screenshots
                    })
                    if (!ss_response.ok) {
                        alert('Что-то пошло не так!', response.status, '!')
                        break
                    }
                }
                setLoading(false)
                onClose();
                window.location.reload()

            } else if (response.status === 401) {
                alert('Токен авторизации устарел. Пожалуйста, войдите в аккаунт заново.')
                setLoading(false)
                logoutUser()
            } else {
                alert('Что-то пошло не так!', response.status, '!')
                setLoading(false)
            }
        }
    }
    return {HandleSubmitAddWork}
}