import styled from "styled-components";
import { NavLink as Link } from 'react-router-dom'

export const Nav = styled.nav`
margin: 0px;
padding: 0px;
background: #05B9BE;
height: 40px;
display: flex;
flex-direction: row;
align-items: center;
`;
export const NavLink = styled(Link)`
    color: #fff;
    text-decoration:none;
    align-items: end;
    margin-right: 30px;
    font-family: "PFBeauSansProLight";
    font-size: 15px;

    &:hover {
        text-decoration: underline;
    }

    &.active {
        text-decoration: underline;
    }
`;