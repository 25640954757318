import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { moduleSelectors } from "../../../store";
import FormGallery from "../../../components/gallery/FormGallery/FormGallery";
import delete24 from "../../../images/icons/delete24.svg";
import stylus from "../../../images/icons/stylus__24.svg"
import add_circle from '../../../images/icons/add_circle.svg'
import ModalChangeModule from "./ModalChangeModule/ModalChangeModule";
import DeleteModal from "../../../components/modal/DeleteModal";
import AuthContext from "../../../context/AuthContext";
import chevron_right from "../../../images/icons/chevron_right.svg"
import chevron_right_disabled from "../../../images/icons/chevron_right_disabled.svg"
import chevron_left from "../../../images/icons/chevron_left.svg"
import chevron_left_disabled from "../../../images/icons/chevron_left_disabled.svg"
import './PanelModule.css'
import LoadingScreen from "../../../components/Loader/Loader";


export default function PanelModule() {
    let { token } = useContext(AuthContext)
    const [isLoading,setIsLoading]= useState(false)
    const { modules, isLoading: isLoadingModules } = useSelector(moduleSelectors);
    const [moduleToEdit, setModuleToEdit] = useState(null);
    const [moduleToDelete, setModuleToDelete] = useState(null);
    const [isDelete, setIsDelete] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [lastModuleId, setLastModuleId] = useState(null);



    const handleDeleteClick = (module) => {
        setIsDelete(true)
        setModuleToDelete(module)
    };

    const handleEditClick = (module) => {
        setIsEdit(true)
        setModuleToEdit(module)
    };




    async function deleteModule(id) {
        await fetch(`https://kiberonevl.ru/api/modules/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        })
    }
    useEffect(() => {
        // This effect will run after the component renders
        if (modules && modules.length > 0) {
            // Get the ID of the last module
            const lastModuleId = modules[modules.length - 1].id;
            setLastModuleId(lastModuleId);
        }
    }, [modules]);


    let [filtredModules, setFiltredModules] = useState([]);
    const [inputs, setInputs] = useState({ modules: [], searchValue: '' });

    const filterModules = (dataModules, value) => {
        const { modules = [], searchValue = '' } = value;

        const ModulesFilteredByModules = dataModules
            .filter(
                (i) =>
                    modules.length === 0 ||
                    modules.some((module) => module.id === i.module.id),
            )
            .filter((i) =>
                i.title.toString().toLowerCase().includes(searchValue.toLowerCase()),
            );
        return ModulesFilteredByModules;
    };
    useEffect(() => {
        setFiltredModules(() => filterModules(modules, inputs));
    }, [inputs]);


    const itemsPerPage = 8;
    const [currentPage, setCurrentPage] = useState(1);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const visibleModules = filtredModules.slice(startIndex, endIndex);

    return !isLoadingModules?(
        <div className="page__works">
            <FormGallery
                modules={modules}
                setInputs={setInputs}
            />
            <button className={"container-panel-students__btn-add-students"} onClick={() => setIsOpen(!isOpen)}><img src={add_circle} />Создать модуль</button>
            <div className="container-list-students">
                {modules && visibleModules.map((module) => (
                    <div key={module.id} className="container-list-student">
                        {module.title}
                        <div className="container-btn">
                            <img className="container-btn__change" src={stylus} onClick={() => handleEditClick(module)} />
                            <img className="container-btn__delete" src={delete24} onClick={() => handleDeleteClick(module)} />
                        </div>
                        {isDelete && module === moduleToDelete && (
                            <DeleteModal
                                isOpen={isDelete}
                                onClose={() => setIsDelete(false)}
                                toRefuse={() => setIsDelete(false)}
                                toAgree={() => deleteModule(module.id)}
                                question={"Уверены, что хотите удалить модуль?"}

                            />
                        )}
                        {isEdit && module == moduleToEdit
                            && (
                                <ModalChangeModule isOpen={isEdit} onClose={() => setIsEdit(false)} oldDataModule={module} question={"Редактровать модуль"} />
                            )}

                    </div>


                ))}
                <div className="pagination">
                    {8 * currentPage - 7}–{8 * currentPage} из {filtredModules.length}
                    <button
                        className="pagination_btn"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        {currentPage !== 1 ? <img src={chevron_left} /> : <img src={chevron_left_disabled} />}
                    </button>
                    {/* <span>{currentPage}</span> */}
                    <button
                        className="pagination_btn"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={endIndex >= filtredModules.length}
                    >
                        {endIndex >= filtredModules.length ? <img src={chevron_right_disabled} /> : <img src={chevron_right} />}
                    </button>
                </div>
                {isOpen && <ModalChangeModule last_id={lastModuleId} isOpen={isOpen} onClose={() => setIsOpen(false)} question={"Создать новый модуль"} />
                }
            </div>
        </div>
    ):<LoadingScreen/>
}