import React, { useState } from "react";
import './SearchInput.css';  // Assuming you have a CSS file for styling

import search from '../../pages/images/search.svg';
import { useForm } from '../../hooks/useForm';
import { right } from "@popperjs/core";


function SearchInput({setInputs,placeholder}) {
  const { values, handleChange, setValues } = useForm();
  const [InputData, setInputData] = useState('');

  const sendDataToParent = () => {
    setInputs(InputData);
  };
  const resetSearch = () => {
    setInputData("")
    setInputs("");
  };

  const handleKeyDown = (event) => {
    
    if (event.key === 'Enter') {
      sendDataToParent()
    }
  };
  return (
    <div className="search_field">
      <div className="search__search-fieldr search-field ">
        <input
          className="search_input"
          name="search"
          onChange={(e) => setInputData(e.target.value)}
          value={InputData}
          placeholder={placeholder===null?"Найти работу по названию":placeholder}
          onKeyDown={handleKeyDown}
        />
        {true ? (
          <button
            type="submit"
            className={`search-field__clean ${InputData===''?'visible':''}`}
            aria-label="Очистить форму поиска"
            onClick={resetSearch}
            style={{marginRight:"10px"}}
          ></button>
        ) : null}
      </div>
      <button
        type="submit"
        className="submit__button_secondary button_secondary button_secondary_blue"
        onClick={sendDataToParent}>
        Найти
      </button>
    </div>)
}

export default SearchInput;