import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  createModule,
  deleteModuleById,
  getModuleById,
  getModules,
  patchModuleById,
  putModuleById,
} from './actions';

const initialState = {
  modules: [],
  currentModule: [],
  isLoading: false,
  isSuccess: false,
  status: null,
  errorMessage: '',
};

const modulesSlice = createSlice({
  name: 'modules',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getModules.pending, (state) => {
        // console.log('pending');
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(getModules.fulfilled, (state, { payload }) => {
        // console.log('fulfilled', payload);
        state.status = 'success';
        state.isLoading = false;
        state.isSuccess = true;
        state.modules = payload;
      })
      .addCase(getModules.rejected, (state, { payload }) => {
        // console.log('rejected', payload);
        state.status = 'rejected';
        state.isLoading = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      });
    builder
      .addCase(createModule.pending, (state) => {
        // console.log('pending');
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(createModule.fulfilled, (state, { payload }) => {
        // console.log('fulfilled', payload);
        state.status = 'success';
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(createModule.rejected, (state, { payload }) => {
        // console.log('rejected', payload);
        state.status = 'rejected';
        state.isLoading = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      });
    builder
      .addCase(getModuleById.pending, (state) => {
        // console.log('pending');
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(getModuleById.fulfilled, (state, { payload }) => {
        // console.log('fulfilled', payload);
        state.status = 'success';
        state.isLoading = false;
        state.isSuccess = true;
        state.currentModule = payload;
      })
      .addCase(getModuleById.rejected, (state, { payload }) => {
        // console.log('rejected', payload);
        state.status = 'rejected';
        state.isLoading = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      });
    builder
      .addCase(putModuleById.pending, (state) => {
        // console.log('pending');
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(putModuleById.fulfilled, (state, { payload }) => {
        // console.log('fulfilled', payload);
        state.status = 'success';
        state.isLoading = false;
        state.isSuccess = true;
        state.currentModule = payload;
      })
      .addCase(putModuleById.rejected, (state, { payload }) => {
        // console.log('rejected', payload);
        state.status = 'rejected';
        state.isLoading = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      });
    builder
      .addCase(patchModuleById.pending, (state) => {
        // console.log('pending');
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(patchModuleById.fulfilled, (state, { payload }) => {
        // console.log('fulfilled', payload);
        state.status = 'success';
        state.isLoading = false;
        state.isSuccess = true;
        state.currentModule = payload;
      })
      .addCase(patchModuleById.rejected, (state, { payload }) => {
        // console.log('rejected', payload);
        state.status = 'rejected';
        state.isLoading = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      });
    builder
      .addCase(deleteModuleById.pending, (state) => {
        // console.log('pending');
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(deleteModuleById.fulfilled, (state, { payload }) => {
        // console.log('fulfilled', payload);
        state.status = 'success';
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(deleteModuleById.rejected, (state, { payload }) => {
        // console.log('rejected', payload);
        state.status = 'rejected';
        state.isLoading = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      });
  },
});

export default modulesSlice.reducer;
