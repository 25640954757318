import React, { useEffect, useState } from 'react';
import './inex.css';

export const StatusElement = ({ work }) => {
  const { is_moderated = false, is_public = false, is_rejected = false } = work;

  const renderCircleSVG = (color) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none">
        <circle
          cx="6"
          cy="6"
          r="6"
          fill={color || '#fff'}
        />
      </svg>
    );
  };
  return (
    <span className="status-publication">
      {(!is_public && (
        <>{renderCircleSVG('var(--gray-color-gray)')} Не опубликована</>
      )) ||
        (is_public && is_rejected && (
          <>
            {renderCircleSVG('var(--sysyem-color-system-error)')} На исправление
          </>
        )) ||
        (is_public && is_moderated && !is_rejected && (
          <>
            {renderCircleSVG('var(--sysyem-color-system-sucess)')} Работа
            проверена
          </>
        )) ||
        (is_public && !is_moderated && !is_rejected && (
          <>
            {renderCircleSVG('var(--sysyem-color-system-warning)')} На проверке
          </>
        ))}
    </span>
  );
};
