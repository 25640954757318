import { createSlice } from '@reduxjs/toolkit';
import {
  createWork,
  deleteWorkById,
  getWorkById,
  getWorks,
  patchWorkById,
  putWorkById,
} from './actions';

const initialState = {
  works: [],
  work: [],
  isLoading: false,
  isLoadingWork: false,
  isLoadingWorks: false,
  isLoadingEditWork: false,
  isSuccess: false,
  status: null,
  errorMessage: '',
};

const worksSlice = createSlice({
  name: 'works',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWorks.pending, (state) => {
        state.status = 'loading';
        state.isLoadingWorks = true;
      })
      .addCase(getWorks.fulfilled, (state, { payload }) => {
        // console.log('fulfilled', payload);
        state.status = 'success';
        state.isLoadingWorks = false;
        state.isSuccess = true;
        state.works = payload;
      })
      .addCase(getWorks.rejected, (state, { payload }) => {
        // console.log('rejected', payload);
        state.status = 'rejected';
        state.isLoading = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      });
    builder
      .addCase(createWork.pending, (state) => {
        // console.log('pending');
        state.status = 'loading';
        state.isLoadingWork = true;
      })
      .addCase(createWork.fulfilled, (state, { payload }) => {
        // console.log('fulfilled', payload);
        state.status = 'success';
        state.isLoadingWork = false;
        state.isSuccess = true;
      })
      .addCase(createWork.rejected, (state, { payload }) => {
        // console.log('rejected', payload);
        state.status = 'rejected';
        state.isLoadingWork = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      });
    builder
      .addCase(getWorkById.pending, (state) => {
        // console.log('pending');
        state.status = 'loading';
        state.isLoadingWork = true;
      })
      .addCase(getWorkById.fulfilled, (state, { payload }) => {
        // console.log('fulfilled', payload);
        state.status = 'success';
        state.isLoadingWork = false;
        state.isSuccess = true;
        state.work = payload;
      })
      .addCase(getWorkById.rejected, (state, { payload }) => {
        // console.log('rejected', payload);
        state.status = 'rejected';
        state.isLoadingWork = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      });
    builder
      .addCase(putWorkById.pending, (state) => {
        // console.log('pending');
        state.status = 'loading';
        state.isLoadingEditWork = true;
      })
      .addCase(putWorkById.fulfilled, (state, { payload }) => {
        // console.log('fulfilled', payload);
        state.status = 'success';
        state.isLoadingEditWork = false;
        state.isSuccess = true;
        state.work = payload;
      })
      .addCase(putWorkById.rejected, (state, { payload }) => {
        // console.log('rejected', payload);
        state.status = 'rejected';
        state.isLoadingEditWork = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      });
    builder
      .addCase(patchWorkById.pending, (state) => {
        // console.log('pending');
        state.status = 'loading';
        state.isLoadingEditWork = true;
      })
      .addCase(patchWorkById.fulfilled, (state, { payload }) => {
        // console.log('fulfilled', payload);
        state.status = 'success';
        state.isLoadingEditWork = false;
        state.isSuccess = true;
        state.work = payload;
      })
      .addCase(patchWorkById.rejected, (state, { payload }) => {
        // console.log('rejected', payload);
        state.status = 'rejected';
        state.isLoadingWork = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      });
    builder
      .addCase(deleteWorkById.pending, (state) => {
        // console.log('pending');
        state.status = 'loading';
        state.isLoadingWork = true;
      })
      .addCase(deleteWorkById.fulfilled, (state, { payload }) => {
        // console.log('fulfilled', payload);
        state.status = 'success';
        state.isLoadingWork = false;
        state.isSuccess = true;
        state.work = [];
      })
      .addCase(deleteWorkById.rejected, (state, { payload }) => {
        // console.log('rejected', payload);
        state.status = 'rejected';
        state.isLoadingWork = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      });
  },
});

export default worksSlice.reducer;
