import './NotFound.css'

export default function NotFound() {
    return (
        <div className="MobileDevice">
            <div className="MobileDevice__main-text">К сожалению старницы по такому адресу не существует</div>
            <div className="MobileDevice__secondary-text">Error 404</div>
        </div>
    );
}
