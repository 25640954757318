import React, { useState, useEffect } from 'react';
import './Gallery.css';
import CardGallery from '../../components/gallery/CardGallery/CardGallery';
import FormGallery from '../../components/gallery/FormGallery/FormGallery';
import { useDispatch, useSelector } from 'react-redux';
import {
  getModules,
  getWorks,
  moduleSelectors,
  workSelectors,
} from '../../store';
import LoadingScreen from '../../components/Loader/Loader';

const Gallery = () => {
  const dispatch = useDispatch();

  const { works, isLoadingWorks } = useSelector(workSelectors);
  const { modules, isLoading: isLoadingModules } = useSelector(moduleSelectors);
  let [isLoading, setLoading] = useState(false);
  let [filtredWorks, setFiltredWorks] = useState([]);
  // console.log("ws", works)
  useEffect(() => {
    isLoadingWorks || isLoadingModules ? setLoading(true) : setLoading(false);
  }, [isLoadingWorks, isLoadingModules]);

  useEffect(() => {
    document.title = 'Галерея';
    dispatch(getWorks());
    dispatch(getModules());
  }, []);

  // filter works
  const [inputs, setInputs] = useState({ modules: [], searchValue: '' });

  const filterWorks = (dataCards, value) => {
    const { modules = [], searchValue = '' } = value;
    // console.log(modules, searchValue, dataCards);
    // console.log(value);

    const workFilteredByModules = dataCards
      .filter(
        (i) =>
          modules.length === 0 ||
          modules.some((module) => module.id === i.module.id),
      )
      .filter((i) =>
        i.title.toString().toLowerCase().includes(searchValue.toLowerCase()),
      );
    // console.log(workFilteredByModules);
    return workFilteredByModules;
  };
  useEffect(() => {
    setFiltredWorks(() => filterWorks(works, inputs));
  }, [inputs]);

  useEffect(() => {
    // console.log(modules);
  }, [modules]);
  // render
  return isLoading ? (
    <LoadingScreen />
  ) : (
    <section className="page__gallery gallery">
      <h1 className="gallery__title">Общая галерея работ</h1>
      <FormGallery
        modules={modules}
        setInputs={setInputs}
      />

      <ul className="gallery__cards">
        {filtredWorks
          ?.map((work) => {
            return (
              !work.is_rejected && work.is_moderated && (
                <CardGallery
                  card={work}
                  key={work.id}
                />
              )
            );
          })
          .reverse()}
      </ul>
    </section>
  );
};

export default Gallery;
