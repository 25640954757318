import React, { useContext } from 'react';
import './index.css';
import { Link, NavLink, useLocation } from 'react-router-dom';
import  KiberLogo from '../../images/icons/KiberLogo2.png';
import AuthContext from '../../context/AuthContext';

const Header = () => {
  const { user } = useContext(AuthContext);
  const location = useLocation()

  return (
    <header className="page__header header">
      <nav className="header__nav nav">
        <div className="nav__wrapper nav__wrapper_left">
          <a
            className="nav__logo-link"
            href="https://kiber-one.com/"
            target="_blank"
            replace="true">
            <img src={KiberLogo} width={210}/>
          </a>
          {user?.is_staff ? (
            <NavLink
              to="/admin"
              className="nav__link"
              activestyle="true">
              Админ панель
            </NavLink>
          ) : null}
        </div>
        {user ? (
          <>
            <div className="nav__wrapper nav__wrapper_center">
              <NavLink
                to="/account"
                className="nav__link"
                activestyle="true">
                Моя страница
              </NavLink>

              <NavLink
                to="/gallery"
                className="nav__link"
                activestyle="true">
                Галерея
              </NavLink>

              {/* <NavLink
                to="/notes"
                className="nav__link"
                activestyle="true">
                Заметки
              </NavLink> */}
            </div>
            <div className="nav__wrapper nav__wrapper_right">
              <NavLink
                to="/exit"
                replace
                state={{
                  backgroundLocation: location,
                  initialPath: location.pathname,
                }}
                className="nav__link"
                activestyle="true">
                Выход
              </NavLink>
            </div>
          </>
        ) : null}
      </nav>
    </header>
  );
};

export default Header;
