import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react'
import Rectangle from '../../pages/images/Rectangle 13739.svg';
import './ListItem.css';
import { useStatusWork } from '../../hooks/useStatusWork';
import { getToken } from '../../utils/tokenStorage';
import { StatusElement } from '../StatusElement';
import { useGoLinkToModal } from '../../hooks/useGoLinkToModal';
function title(title) {

    if (title.length > 20) {
        return title.substr(0, 24) + '...';
    }
    else {
        return title;
    }
}
const ListItem = ({ work, user, inputs, status, istaff }) => {
    const { goLinkToModal } = useGoLinkToModal();
    const screenshot = istaff ? work?.screenshots[0]?.screenshot : "https://kiberonevl.ru/" + work?.screenshots[0]?.screenshot;

    // Фильтрация работ по названию
    const isTitleMatch = (inputs || inputs === "") && work.title.toLowerCase().includes(inputs.toLowerCase());
    let isStatusMatch = true;

    if (status === "verified") {
        isStatusMatch = work.is_public && work.is_moderated && !work.is_rejected;
    }
    else if (status === "notPublic") {
        isStatusMatch = !work.is_public;
    }
    else if (status === "notPublic") {
        isStatusMatch = !work.is_public;
    }
    else if (status === "onVerification") {
        isStatusMatch = work.is_public && !work.is_moderated && !work.is_rejected;
    }
    else if (status === "forСorrection") {
        isStatusMatch = work.is_public && work.is_rejected;
    }

    return isTitleMatch && isStatusMatch ? (
        <div className="Work_grid" onClick={() => goLinkToModal(`/works/${work.id}`, work)}>
            {Array.isArray(work.screenshots) && work.screenshots.length !== 0 ? (
                <img className='Work_Screen' src={screenshot} alt='' aria-hidden="true" />
            ) : (
                <img src={Rectangle} />
            )}
            <label className='Autor_name'>{title(work?.title)}</label>
            <label className='Work_name_module'>{title(work.module?.short_title_with_default)}</label>
            <div className='listItem__div__cont_status'><StatusElement work={work} user={user} /></div>
        </div>
    ) : null;
};

export default ListItem;
