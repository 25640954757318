import { useLocation, useNavigate } from 'react-router-dom';

export const useGoLinkToModal = () => {
  const navigate = useNavigate();
  const location = useLocation();
  function goLinkToModal(link, stateData, linkState) {
    linkState
      ? navigate(link, {
          replace: true,
          state: {...linkState,stateData}
        })
      : navigate(link, {
          replace: true,
          state: {
            backgroundLocation: { ...location, state: stateData || null },
            initialPath: location.pathname,
          },
        });
  }
  return { goLinkToModal };
};
