import { useEffect, useState } from "react";
import ModulesBar from "../../../components/ModulesBar/ModuleBar";
import SearchInput from "../../../components/SearchInput/SearchInput";
import AuthContext from "../../../context/AuthContext";
import { useContext } from "react";
import axios from "axios";
import ListItem from "../../../components/ListItem/ListItem";
import CardGallery from "../../../components/gallery/CardGallery/CardGallery";
import FormGallery from "../../../components/gallery/FormGallery/FormGallery";
import { getModules, getWorks, moduleSelectors, workSelectors } from "../../../store";
import { useDispatch, useSelector } from 'react-redux';
import LoadingScreen from "../../../components/Loader/Loader";
import './PanelWork.css'


export default function PanelWork() {
    const dispatch = useDispatch();
    const [selected, setSelected] = useState('all');
    let [isLoading, setLoading] = useState(false);
    const { modules, isLoading: isLoadingModules } = useSelector(moduleSelectors);
    const { works, isLoadingWorks } = useSelector(workSelectors);
    let [currentModule, setCurrentModule] = useState(null)
    let [filtredWorks, setFiltredWorks] = useState([]);
    

    useEffect(() => {
        isLoadingWorks || isLoadingModules ? setLoading(true) : setLoading(false);
    }, [isLoadingWorks, isLoadingModules]);

    useEffect(() => {

        dispatch(getWorks());
        dispatch(getModules());
    }, []);

    const [inputs, setInputs] = useState({ modules: [], searchValue: '' });

    const filterWorks = (dataCards, value) => {
        const { modules = [], searchValue = '' } = value;
        // console.log(modules, searchValue, dataCards);
        // console.log(value);

        const workFilteredByModules = dataCards
            .filter(
                (i) =>
                    modules.length === 0 ||
                    modules.some((module) => module.id === i.module.id),
            )
            .filter((i) =>
                i.title.toString().toLowerCase().includes(searchValue.toLowerCase()),
            );
        // console.log(workFilteredByModules);
        return workFilteredByModules;
    };
    useEffect(() => {
        setFiltredWorks(() => filterWorks(works, inputs));
    }, [inputs]);

    useEffect(() => {
        // console.log(modules);
    }, [modules]);

    function checkStatus(status, card) {
        if (status == "all") {
            return true
        }else if (status === "verified") {
            return card.is_public && card.is_moderated && !card.is_rejected;
        }
        else if (status === "notPublic") {
            return !card.is_public;
        }
        else if (status === "notPublic") {
            return !card.is_public;
        }
        else if (status === "onVerification") {
            return card.is_public && !card.is_moderated && !card.is_rejected;
        }
        else if (status === "forСorrection") {
            return card.is_public && card.is_rejected;
        }
    }


    return isLoading ? (
        <LoadingScreen />
    ) : (
        <section className={"page__works"}>
            <FormGallery
                modules={modules}
                setInputs={setInputs}
            />

            <ModulesBar onSelected={setSelected} />

            <ul className="gallery__cards">
                {filtredWorks
                    ?.map((work) => {
                        return (
                            checkStatus(selected, work) &&
                            <CardGallery
                                card={work}
                                key={work.id}
                                isStaff={true}
                                status={selected}
                            />
                            // )
                        );
                    })
                    .reverse()}
            </ul>
        </section>
    );
}