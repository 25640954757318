import React, { useState, useEffect, useRef, useContext } from "react";
import './Avatar.css'
import AgreeChangePhotoModal from "../modal/AgreeChangePhotoModal";
import { useGoLinkToModal } from '../../hooks/useGoLinkToModal'
import LoadingScreen from "../Loader/Loader";
import AuthContext from "../../context/AuthContext";


export default function Avatar({ user, uploadData }) {
    let { token } = useContext(AuthContext)
    const [avatar, setAvatar] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [userAvatar, setUserAvatar] = useState(null);
    const { goLinkToModal } = useGoLinkToModal()
    const [isAgreeChangePhotoModal, setisAgreeChangePhotoModal] = useState(false)
    // useEffect(() => {
    //     const accessToken = localStorage.getItem('accessToken');
    //     setToken(accessToken);
    // }, []);

    useEffect(() => {
        const trySetUserAvatar = async () => {
            try {
                if (user && user.extra) {
                    setUserAvatar(user.extra.avatar || null);
                } else {

                }
            } catch {

                setTimeout(trySetUserAvatar, 100);
            }
        };

        trySetUserAvatar();
    }, [user]);


    useEffect(() => {
        if (avatar) {
            handleAvatarChange();
        }
    }, [avatar]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setAvatar(file);

    };

    const handleAvatarChange = async (e) => {

        setLoading(true)

        try {
            const formData = new FormData();
            formData.append('avatar', avatar);
            const response = await fetch(`https://kiberonevl.ru/api/extra/${user.extra.id}/`, {
                method: 'PATCH',
                headers: {
                    'Authorization': 'Bearer ' + token,
                },
                body: formData
            },
            );

            if (!response.ok) {
                throw new Error('Failed to update avatar');
            }
            else {
                setUserAvatar(user.extra.avatar || null);
                window.location.reload(false);
            }


            const data = await response.json();
        } catch (error) {
            console.error('Error updating avatar:', error);
        } finally {
            setUserAvatar(user.extra.avatar || null);
        }
    };

    const handleAvatar = (file) => {
        setAvatar(file)
        handleAvatarChange(file);
    }
    const handleModal=(e)=>{
        e.preventDefault();
        setisAgreeChangePhotoModal(true);
    }
    return !isLoading?(
        <form className="avatar_form">
            {userAvatar && <img src={userAvatar} width={'100px'} alt="User Avatar" className="avatar_img" />}
            <input type="button" className="avatar_change" onClick={handleModal} accept="image/*"/>
            {isAgreeChangePhotoModal ? (
            <AgreeChangePhotoModal
                isOpen={isAgreeChangePhotoModal}
                onClose={() => setisAgreeChangePhotoModal(false)}
                toAgree={(file) => {
                    setisAgreeChangePhotoModal(false);
                    handleAvatar(file);
                }}
            />) 
            : null}
        </form>
    ):<><LoadingScreen/></>;
}
