import React from 'react'
import eye_open from './../pages/images/eye_open.svg'
import eye_closed from './../pages/images/eye_closed.svg'

export default class DoubleClick extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
        type: 'passsword',
        visibility: true
    };

    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    this.setState(state => ({ 
        type: 'text',
        visibility: !state.visibility
    }));
  }

  render() {
    if(this.state.visibility){
    return (
      <div>
         <input className="Entry_input" type="password" name="password"></input>
         <img src={eye_closed} id="pass_closed"onClick={this.handleClick}></img>
      </div>
    );
    
    } else{
        return(
         <div>
            <input className="Entry_input" type="text" name="password"></input>
            <img src={eye_open} id="pass_open"onClick={this.handleClick}></img>
         </div>
        
        )
    }
}
}
