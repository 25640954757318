import React, { Component } from 'react'
import Leftbtn from '../pages/images/Leftbtn.svg'
import Rightbtn from '../pages/images/Rightbtn.svg'


class Slider extends Component {

    constructor(props){
        super()
        this.state=({currentSlideImage : 0})
    }

    handlePrevButton() {
        let currentSlideImage = this.state.currentSlideImage
        if(currentSlideImage == 0) {
            this.setState({currentSlideImage: this.props.slides.length - 1})
        } else {
            this.setState({currentSlideImage: currentSlideImage - 1})
        }
    }

    handleNextButton() {
        let currentSlideImage = this.state.currentSlideImage
        if(currentSlideImage == this.props.slides.length - 1) {
            this.setState({currentSlideImage: 0})
        } else {
            this.setState({currentSlideImage: currentSlideImage + 1})
        }
    }

    getIndex() {
        return this.state.currentSlideImage >= this.props.slides.length ? 0 : this.state.currentSlideImage
    }

    render() {
        return (
            <div id="container">
                <img id='prevbutt' src={Leftbtn} onClick={()=>this.handlePrevButton()}></img>
                <img id='slideimg' src={this.props.slides[this.getIndex()]} alt="slider-item"/>
                <img id='nextbutt' src={Rightbtn} onClick={()=>this.handleNextButton()}></img>
            </div>
        );
    }
}

export default Slider;