import React, { useContext, useEffect, useState } from 'react'
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom'
import AuthContext, { AuthProvider } from './context/AuthContext'
import Navbar from './components/Navbar'
import Gallery from './pages/Gallery/Gallery'
import Notes from './pages/Notes'
import Account from './pages/Account'
import Entrance from './pages/Entrance/Entrance'
import Admin_add from './pages/Admin_add'
// import Exit from './pages/old/Exit'
import Work_page from './pages/Work_page'
import Edit_work from './pages/Edit_work'
import Slider from './components/Slider'
import AddWork from './components/AddWork'
import Showtopicbtn from './components/Showtopicbtn'
import Password from './components/Password'
import PrivateRoute from './utils/PrivateRoute'
import Change_avatar from './components/Change_avatar'
import WorkPage from './pages/WorkPage'
import Header from './components/Header'
import RoutingModal from './components/modal/RoutingModal'
import AddNewWork from './components/AddNewWork/AddNewWork'
import Exit from './components/modal/Exit'
import './App.css'

import { switchBodyScrollbar } from './utils/switchBodyScrollbar'
import AgreeChangePhotoModal from './components/modal/AgreeChangePhotoModal'
import EditWork from './components/EditWork/EditWork'
import AdminPanel from './pages/AdminPanel/AdminPanel'
import NotFound from './components/NotFound/NotFound'


const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const backgroundLocation = location.state?.backgroundLocation;
  const initialPath = location.state?.initialPath;
  const onCloseRoutingModal = (route) => {
    navigate(initialPath || route, { replace: true })
    switchBodyScrollbar(false);

  }
  const [linkState, setLinkState] = useState(null)
  const [stateData, setStateData] = useState(null);


  useEffect(() => {
    setLinkState({ backgroundLocation, initialPath })
  }, [backgroundLocation, initialPath])

  return (
    <AuthProvider>
      <Header />
      {/* <Navbar /> */}
      <Routes location={(backgroundLocation && { ...backgroundLocation, pathname: initialPath }) || location}>
        <Route path='/' element={<Entrance />} />
        <Route element={<PrivateRoute context={AuthContext} />}>
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/notes' element={<Notes />} />
          <Route path='/account' element={<Account />} />
          <Route path='/exit' element={
            <RoutingModal isOpen={true} onClose={() => onCloseRoutingModal('/account')}>
              <Exit onClose={() => onCloseRoutingModal('/gallery')} />
            </RoutingModal>
          } />
          <Route path="/works/:id" element={
            <RoutingModal isOpen={true} onClose={() => onCloseRoutingModal('/gallery')}>
              <WorkPage onClose={() => onCloseRoutingModal('/gallery')} />
            </RoutingModal>
          } />
          {/* <Route path="/add-work" element={<AddWork />} /> */}
          <Route path="/add-work" element={
            <RoutingModal isOpen={true} onClose={() => onCloseRoutingModal('/account')}>
              <AddNewWork onClose={() => onCloseRoutingModal('/account')} />
            </RoutingModal>
          } />
          <Route path="/addWork" element={<AddNewWork />} />
          <Route path="/works/:id/edit" element={<EditWork />} />
          <Route path="/addWork" element={<AddNewWork />} />
          <Route path="/admin" element={<AdminPanel />} />
          <Route path="/Slider" element={<Slider />} />
          <Route path="/Change_avatar" element={<Change_avatar />} />
          <Route path='*' element={<NotFound/>} />
        </Route>
        <Route path='*' component={<NotFound/>} />
      </Routes>

      {backgroundLocation &&
        <Routes>
          <Route path="/works/:id" element={
            <RoutingModal isOpen={true} onClose={() => onCloseRoutingModal('/gallery')}>
              <WorkPage onClose={() => onCloseRoutingModal('/gallery')} linkState={linkState} />
            </RoutingModal>
          } />
          <Route path="/add-work" element={
            <RoutingModal isOpen={true} onClose={() => onCloseRoutingModal('/account')}>
              <AddNewWork onClose={() => onCloseRoutingModal('/account')} />
            </RoutingModal>
          } />
          <Route path="/exit" element={
            <RoutingModal isOpen={true} onClose={() => onCloseRoutingModal('/account')}>
              <Exit onClose={() => onCloseRoutingModal('/account')} />
            </RoutingModal>
          } />
          <Route path='*' component={<NotFound/>} />
        </Routes>
      }
    </AuthProvider>
  )
}

export default App
