import './../index.css';
import { ReactComponent as CloseModalSVG } from '../../../images/icons/close-24.svg';

function DeleteModal({ isOpen, onClose, toAgree, toRefuse, question }) {
  return isOpen ? (
    <div onMouseDown={(e) => e.stopPropagation()}>
      <div
        className={`modal moda_type_question ${isOpen && 'modal_active'}`}
        onMouseDown={onClose}>
        <div
          className="modal__container modal__container_question"
          onMouseDown={(e) => e.stopPropagation()}>
          <div className="modal__header">
            <button
              type="button"
              className="modal__close-button"
              onClick={() => onClose()}>
              <CloseModalSVG />
            </button>
          </div>
          <div className="modal__wrapper">
            <h2 className="modal__question">
              {question}
            </h2>
            <div className="modal__buttons modal__buttons_row">
              <button
                type="button"
                className="button_secondary button_secondary_white"
                onClick={() => toRefuse()}>
                Отмена
              </button>
              <button
                type="button"
                className="button_secondary button_secondary_red"
                onClick={() => toAgree()}>
                Удалить
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

export default DeleteModal;
