import { useContext, useEffect, useState } from 'react';
import InputWithLabel from '../../../../components/InputWithLabel/InputWithLabel';
import CloseModalSVG from '../../../../images/icons/close-24.svg';
import SubmitButton from '../../../../components/SubmitButton/SubmitButton';
import AuthContext from '../../../../context/AuthContext';
import "./ModalChangeModule.css"
import "../../PanelStudents/ModalAddStudent/PublishModal.css";

export default function ModalChangeModule({ isOpen, onClose, question, oldDataModule, last_id }) {
    let [isLoading, setLoading] = useState(false)
    let { token } = useContext(AuthContext)
    const [validInputTitle,setValidInputTitle]= useState("200")
    const [validInputdescription,setValidInputdescription]= useState("200")

    const [module, setModule] = useState({
        description: '',
        id: '',
        short_title: '',
        short_title_with_default: "",
        title: ""
    })

    function handleTitleChange(event) {
        setModule({ ...module, title: event.target.value })
        if(event.target.value){
            setValidInputTitle("200")}
    }

    function handleShortTitleChange(event) {
        setModule({ ...module, short_title: event.target.value })
    }
    function handleDescriptionChange(event) {
        setModule({ ...module, description: event.target.value })
        setValidInputdescription("200")
    }

    async function createModule() {
        if(!module.title){
            setValidInputTitle("Введите название модуля")
            return
        }else if(!module.description){
            setValidInputdescription("0")
        }
        setLoading(true)
        let response = await fetch(`https://kiberonevl.ru/api/modules/create/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({
                'title': `Модуль ${last_id}. ${module.title}`,
                'short_title': module.short_title,
                'description': module.description
            })
        })
        if (response.ok) {
            setLoading(false);
            window.location.reload();

        } else if (response.status === 400) {
            alert('Введены некорректные данные или пользователь с такой почтой уже существует!')
            setLoading(false)
        } else if (response.status === 401) {
            alert('Токен авторизации устарел. Пожалуйста, войдите в аккаунт заново.')
            setLoading(false)
        } else {
            alert('Что-то пошло не так!')
            setLoading(false)
        }
    }

    async function EditModule() {
        if (isLoading) {
            alert('Подождите, идет загрузка')
            return
        }
        setLoading(true)
        let moduleBody = {
            'title': module.title,
            'short_title': module.short_title,
            'description': module.description
        }
        let response = await fetch(`https://kiberonevl.ru/api/modules/${module.id}/`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(moduleBody),
        })
        if (response.ok) {
            window.location.reload();
        } else if (response.status === 400) {
            alert('Введены некорректные данные!')
            setLoading(false)
        } else if (response.status === 401) {
            alert('Токен авторизации устарел. Пожалуйста, войдите в аккаунт заново.')
            setLoading(false)
            //logoutUser()
        } else {
            alert('Что-то пошло не так!')
            setLoading(false)
        }
    }

    useEffect(() => {
        if (oldDataModule) {
            setModule(oldDataModule)
        }
    }, [oldDataModule])

    return isOpen ? (
        <div onMouseDown={(e) => e.stopPropagation()}>
            <div
                className={`modal moda_type_question ${isOpen && 'modal_active'}`}
                onMouseDown={onClose}>
                <div
                    className="modal__container modal__container_question_add_student"
                    onMouseDown={(e) => e.stopPropagation()}>
                    <div className="modal__header">
                        <button
                            type="button"
                            className="modal__close-button"
                            onClick={() => onClose()}>
                            <img className={"modal__close_img"} src={CloseModalSVG} />
                        </button>
                    </div>
                    <div className="modal__wrapper-module">
                        <br></br>
                        <h2 className="modal__question">
                            {question}
                        </h2>
                        <InputWithLabel width={736} className={"big"} textLabel={"Название модуля"} placeholder={"Введите название модуля"} errorStatus={validInputTitle} onChange={handleTitleChange} defaultValue={oldDataModule?.title || ""} />
                        <InputWithLabel width={736}  className={"big"} textLabel={"Короткое название модуля"} placeholder={"Введите короткое название модуля "} errorStatus={"200"} onChange={handleShortTitleChange} defaultValue={oldDataModule?.short_title_with_default || ""} />
                        <div>Описание модуля
                            <textarea className={`modal__wrapper_textarea ${validInputdescription!=="200"?"error":''}`} textLabel={"Описание модуля"} placeholder={"Введите оисание модуля"} onChange={handleDescriptionChange} defaultValue={oldDataModule?.description || ""} />
                            <SubmitButton className={"modal__wrapper_sub-btn"} content={"Сохранить"} onClick={oldDataModule ? EditModule : createModule} />
 
                            </div>
                    </div>
                </div>
            </div>
        </div>
    ) : null;
}