import { useContext, useEffect, useState } from "react";
import SearchInput from "../../../components/SearchInput/SearchInput";
import AuthContext from "../../../context/AuthContext";
import delete24 from "../../../images/icons/delete24.svg";
import stylus from "../../../images/icons/stylus__24.svg"
import ModalAddStudent from "./ModalAddStudent/ModalAddStudent";
import DeleteModal from "../../../components/modal/DeleteModal";
import add_circle from '../../../images/icons/add_circle.svg'
import "./PanelStudents.css"
import LoadingScreen from "../../../components/Loader/Loader";
import chevron_right from "../../../images/icons/chevron_right.svg"
import chevron_right_disabled from "../../../images/icons/chevron_right_disabled.svg"
import chevron_left from "../../../images/icons/chevron_left.svg"
import chevron_left_disabled from "../../../images/icons/chevron_left_disabled.svg"
import file_save from "../../../images/icons/file_save.svg"

export default function PanelStudents() {
    const [users, setUsers] = useState([])
    let [isLoading, setLoading] = useState(false)
    let { user } = useContext(AuthContext)
    let { token } = useContext(AuthContext)
    let { logoutUser } = useContext(AuthContext)
    const [Inputs, setInputs] = useState("")
    const [isOpen, setIsOpen] = useState(false)
    const [isDelete, setIsDelete] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [userToEdit, setUserToEdit] = useState(null);
    const [userToDelete, setUserToDelete] = useState(null);
    
    const handleDeleteClick = (user) => {
        setUserToDelete(user);
        setIsDelete(true);
    };

    const handleEditClick = (user) => {
        setUserToEdit(user);
        setIsEdit(true);
    };

    let getUsers = async () => {
        setLoading(true)
        let response = await fetch(`https://kiberonevl.ru/api/users/`, {
            headers: {
                'Authorization': 'Bearer ' + token,
            },
        })
        if (response.ok) {
            let data = await response.json()
            setUsers(data)
            setLoading(false)
        } else if (response.status === 401) {
            alert('Токен авторизации устарел. Пожалуйста, войдите в аккаунт заново.')
            setLoading(false)
            logoutUser()
        } else {
            alert('Что-то пошло не так!')
            setLoading(false)
        }
    }
    useEffect(() => {
        document.title = "Админ-панель"
        // if (!user?.is_staff) navigate('/account')
        // let getModules = async () => {
        //     setLoading(true)
        //     let response = await fetch(`https://kiberonevl.ru/api/modules/`, {
        //         headers: {
        //             'Authorization': 'Bearer ' + token,
        //         },
        //     })
        //     if (response.ok) {
        //         let data = await response.json()
        //         setModules(data)
        //         setLoading(false)
        //     } else if (response.status === 401) {
        //         alert('Токен авторизации устарел. Пожалуйста, войдите в аккаунт заново.')
        //         setLoading(false)
        //         logoutUser()
        //     } else {
        //         alert('Что-то пошло не так!')
        //         setLoading(false)
        //     }
        // }
        let getUsers = async () => {
            setLoading(true)
            let response = await fetch(`https://kiberonevl.ru/api/users/`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                },
            })
            if (response.ok) {
                let data = await response.json()
                setUsers(data)
                setLoading(false)
            } else if (response.status === 401) {
                alert('Токен авторизации устарел. Пожалуйста, войдите в аккаунт заново.')
                setLoading(false)
                logoutUser()
            } else {
                alert('Что-то пошло не так!')
                setLoading(false)
            }
        }
        // let getWorks = async () => {
        //     setLoading(true)
        //     let response = await fetch('https://kiberonevl.ru/api/works/', {
        //         headers: {
        //             'Authorization': 'Bearer ' + token,
        //         },
        //     })
        //     if (response.ok) {
        //         let data = await response.json()
        //         setWorks(data)
        //         setLoading(false)
        //     } else if (response.status === 401) {
        //         alert('Токен авторизации устарел. Пожалуйста, войдите в аккаунт заново.')
        //         setLoading(false)
        //         logoutUser()
        //     } else {
        //         alert('Что-то пошло не так!')
        //         setLoading(false)
        //     }
        // }
        getUsers()
        // getWorks()
        // getModules()
    }, [])

    const handleDataFromSearchInput = (data) => {
        setInputs(data);
    };

    const filteredUsers = users.filter((user) => {
        // Customize the filtering logic based on your requirements
        return (
            user.first_name.toLowerCase().includes(Inputs.toLowerCase()) ||
            user.last_name.toLowerCase().includes(Inputs.toLowerCase())
        );
    });


    async function deleteStudent(id) {
        await fetch(`https://kiberonevl.ru/api/users/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        })
        getUsers();
        setIsDelete(false);
    }

    async function downloadStudents() {
        try {
            const response = await fetch(`https://kiberonevl.ru/api/download_users/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'text/csv',
                    'Authorization': 'Bearer ' + token,
                }
            });

            if (!response.ok) {
                throw new Error(`Failed to download the file. Status: ${response.status}`);
            }
            

            const blob = await response.blob();

            // Создаем временный URL для Blob
            const url = window.URL.createObjectURL(blob);

            // Создаем ссылку для скачивания
            const link = document.createElement('a');
            link.href = url;
            link.download = 'users.csv'; // Имя файла для скачивания
            document.body.appendChild(link);

            // Имитируем клик по ссылке для скачивания
            link.click();

            // Освобождаем ресурсы
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
        } catch (error) {
            console.error('Download failed:', error.message);
        }
    }

    const itemsPerPage = 8;
    const [currentPage, setCurrentPage] = useState(1);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const visibleUsers = filteredUsers.slice(startIndex, endIndex);

    return !isLoading ? (
        <div className="container-panel-students">
            <div className="container-panel-students__title">Найти ученика</div>
            <SearchInput placeholder={"Начать поиск ученика"} setInputs={handleDataFromSearchInput} />
            <div className="container-panel-students-btn">
                <button className={"container-panel-students__btn-add-students"} onClick={() => setIsOpen(!isOpen)}><img src={add_circle} />Добавить ученика</button>
                <button className={"container-panel-students__download-students"} onClick={() => downloadStudents()}><img src={file_save} />Выгрузить учеников в Excel</button>
            </div>
            <div className="container-list-students">
                {users && visibleUsers.map((user) => (
                    <div key={user.id} className="container-list-student">
                        {user.first_name} {user.last_name}
                        <div className="container-btn">
                            <img className="container-btn__change" src={stylus} onClick={() => handleEditClick(user)} />
                            <img className="container-btn__delete" src={delete24} onClick={() => handleDeleteClick(user)} />
                        </div>
                        {isDelete && user === userToDelete && (
                            <DeleteModal
                                isOpen={isDelete}
                                onClose={() => setIsDelete(false)}
                                toRefuse={() => setIsDelete(false)}
                                toAgree={() => deleteStudent(user.id)}
                                question={"Уверены, что хотите удалить ученика?"}

                            />
                        )}
                        {isEdit && user == userToEdit
                            && <ModalAddStudent
                                isOpen={isEdit}
                                onClose={() => setIsEdit(false)}
                                users={users}
                                question={"Редактировать данные ученика"}
                                oldDataUser={user} />}
                    </div>


                ))}
                <div className="pagination">
                    {8 * currentPage - 7}–{8 * currentPage} из {filteredUsers.length}
                    <button
                        className="pagination_btn"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        {currentPage !== 1 ? <img src={chevron_left} /> : <img src={chevron_left_disabled} />}
                    </button>
                    {/* <span>{currentPage}</span> */}
                    <button
                        className="pagination_btn"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={endIndex >= filteredUsers.length}
                    >
                        {endIndex >= filteredUsers.length ? <img src={chevron_right_disabled} /> : <img src={chevron_right} />}
                    </button>
                </div>
            </div>
            {isOpen && <ModalAddStudent isOpen={isOpen} onClose={() => setIsOpen(false)} users={users} question={"Добавить нового ученика"} />}
        </div>) : <LoadingScreen />
}