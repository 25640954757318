import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

export default class ColoredScrollbars extends Component {
  constructor(props, ...rest) {
    super(props, ...rest);
    this.state = { top: 0 };
    this.handleUpdate = this.handleUpdate.bind(this);
    this.renderThumb = this.renderThumb.bind(this);
  }

  handleUpdate(values) {
    const { top } = values;
    this.setState({ top });
  }

  renderThumb({ style, ...props }) {
    const { top } = this.state;
    const thumbStyle = {
      backgroundColor: `var(--gray-color-gray)`,
      width: 8,
      borderRadius: 100,
      right: 8,
    };
    return (
      <div
        style={{ ...style, ...thumbStyle }}
        {...props}
      />
    );
  }

  render() {
    return (
      <Scrollbars
        renderThumbVertical={this.renderThumb}
        onUpdate={this.handleUpdate}
        thumbSize={40}
        {...this.props}
      />
    );
  }
}
