import React, { useRef, useEffect, useContext, useState } from 'react';
import closeButton from '../../images/icons/close-24.svg'
import closeBlue from '../../images/icons/cross.svg'
import "./EditWork.css"
import InputWithLabel from '../InputWithLabel/InputWithLabel';
import AuthContext from '../../context/AuthContext'
import add_photo_svg from '../../images/icons/icon panel=photo.svg'
import SubmitButton from '../SubmitButton/SubmitButton';
import CustomDropdown from '../CustomDropdown/CustomDropdown';
import PublishModal from '../PublishModal/PublishModal';
import LoadingScreen from '../Loader/Loader';





async function convertURLsToFiles(urls) {
    const fileObjects = [];

    const fetchPromises = urls.map(async (url) => {

        try {
            const response = await fetch(url.screenshot);

            if (!response.ok) {
                console.error('Failed to fetch image. Server returned:', response.status, response.statusText);
                throw new Error('Failed to fetch image.');
            }

            const contentType = response.headers.get('content-type');
            if (!contentType.startsWith('image')) {
                console.error('Invalid content type. Expected an image, but received:', contentType);
                
                // Log the response content to help diagnose the issue
                const responseBody = await response.text();
                console.error('Response content:', responseBody);

                throw new Error('Invalid content type. Expected an image.');
            }

            const blob = await response.blob();
            const fileName = extractFileNameFromURL(url);
            const file = new File([blob], fileName, { type: blob.type });
            fileObjects.push(file);
        } catch (error) {
            console.error('Error processing image:', error);
        }
    });

    try {
        await Promise.all(fetchPromises);
    } catch (error) {
        console.error('Error during image fetch or processing:', error);
    }

    return fileObjects;
}




function extractFileNameFromURL(urlObject) {
    if (!urlObject || typeof urlObject !== 'object' || !urlObject.screenshot) {
        console.error('Invalid URL object:', urlObject);
        return 'unknown_filename';
    }

    const urlParts = urlObject.screenshot.split('/');
    const fileName = urlParts[urlParts.length - 1];
    return fileName || 'unknown_filename';
}





export default function EditWork({ isOpen, onClose, EditWork, idWork, tokenAuth }) {

    const [isLoading, setLoading] = useState(false)
    const [errorTitle, setErrorTitle] = useState("200");
    const [errorScreenshots, seterrorScreenshots] = useState(null)
    const [errorWork, setErrorWork] = useState(null)
    const [isOpenPublishModal, setOpenPublishModal] = useState(false);
    const [errorModal, setErrorModal] = useState(null)
    const [modules, setModules] = useState([])
    const [fileIsUpdate, setfileIsUpdate] = useState(false);
    const [ssIsUpdate, setssIsUpdate] = useState(false);
    let EditWorkSRC = [];
    const [screenshots, setScreenshots] = useState(EditWorkSRC || []);

    EditWork.screenshots.map((item) => { EditWorkSRC.push(item.screenshot) })
    const [src, setSrc] = useState(EditWorkSRC || [])
    const [selectedFile, setSelectedFile] = useState({ file: EditWork.file, name: EditWork.file.match(/\/([^\/]+)$/)[1].length>35? EditWork.file.match(/\/([^\/]+)$/)[1].substring(0, 35) + "...":EditWork.file.match(/\/([^\/]+)$/)[1] } || null);
    const [work, setWork] = useState({
        screenshots: null,
        title: EditWork?.title || '',
        module: EditWork.module || null,
        file: { file: EditWork.file, name: EditWork.file.match(/\/([^\/]+)$/)[1] } || null,
        comment: EditWork.comment || "",
        is_public: null,
    })

    useEffect(() => {
        async function fetchData() {
            const files = await convertURLsToFiles(EditWork.screenshots);

            setWork((prevWork) => ({ ...prevWork, screenshots: files }));
        }

        fetchData();


    }, []);




    useEffect(() => {
        let getModules = async () => {
            let response = await fetch('https://kiberonevl.ru/api/modules/', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + tokenAuth
                },
            })
            if (response.ok) {
                let data = await response.json()
                setModules(data)
            } else if (response.status === 401) {
                alert('Токен авторизации устарел. Пожалуйста, войдите в аккаунт заново.')
                logoutUser()
            } else {

                alert('Что-то пошло не так!', response.status, '!')
            }
        }
        getModules()
    }, [])

    let { user } = useContext(AuthContext)
    let { token } = useContext(AuthContext)
    let { logoutUser } = useContext(AuthContext)

    function handleTitleChange(event) {
        const trimmedValue = event.target.value.trim(); // Удаляем пробелы в начале и конце строки
        if (trimmedValue.length >= 35) {
            setErrorTitle("Название не должно превышать 35 символов");
            return;
        }
        else {
            const capitalizedTitle = trimmedValue.charAt(0).toUpperCase() + trimmedValue.slice(1);
            setWork({ ...work, title: capitalizedTitle });
            setErrorTitle("200");
        }
    }

    function handleCommentChange(event) {
        setWork({ ...work, comment: event.target.value })
    }

    function handleModuleChange(event) {
        const selectedModule = (modules.filter(module => module.id === event.id))[0];
        setWork({ ...work, module: selectedModule })
        setErrorModal(null)
    }

    function handleScreenshotsChange(event) {
        const newScreenshots = [...event.target.files];
        setScreenshots((prevScreenshots) => [...prevScreenshots, ...newScreenshots]);

        getScreenshotsURL(newScreenshots);

        setWork((prevWork) => ({
            ...prevWork,
            screenshots: [...prevWork.screenshots, ...newScreenshots],
        }));

        setssIsUpdate(true);
        seterrorScreenshots(null);
    }


    function handleDeleteScreenshot(index) {
        setScreenshots((prevScreenshots) => {
            const updatedScreenshots = [...prevScreenshots];
            updatedScreenshots.splice(index, 1);
            return updatedScreenshots;
        });

        setSrc((prevSrc) => {
            const updatedSrc = [...prevSrc];
            updatedSrc.splice(index, 1);
            return updatedSrc;
        });

        setWork((prevWork) => {
            const newScreenshots = [...prevWork.screenshots];
            newScreenshots.splice(index, 1);

            return {
                ...prevWork,
                screenshots: newScreenshots,
            };
        });
    }




    function handleCheckChangeIsModal(event) {

        setWork(prevWork => ({
            ...prevWork,
            is_public: true
        }));


    }

    function handleCheckChangeIsNotModal(event) {

        setWork(prevWork => ({
            ...prevWork,
            is_public: false
        }));
    }

    useEffect((e) => {
        if (work.is_public !== null)
            handleSubmit(e)
    }, [work.is_public]);


    function getScreenshotsURL(fileList) {
        if (!fileList || fileList.length === 0) {
            setSrc([]);
            return;
        }
        let newSrc = [];
        Array.from(fileList).forEach((file) => {
            const imageUrl = URL.createObjectURL(file);

            // Check if the image URL already exists in newSrc
            if (!newSrc.includes(imageUrl)) {
                newSrc.push(imageUrl);
            }
        });
        setSrc((prevSrc) => [...prevSrc, ...newSrc]);
    }

    async function handleSubmit(e) {

        
        if (work.file) {
            setLoading(true)
            let form_data = new FormData()
            form_data.append('user', user.id)
            form_data.append('module', work.module.id)
            form_data.append('title', work.title)
            if (fileIsUpdate) {
                form_data.append('file', work.file)
            }
            form_data.append('comment', work.comment)
            form_data.append('is_public', work.is_public)
            form_data.append('is_moderated', false)
            form_data.append('user', user.id)
            let response = await fetch(`https://kiberonevl.ru/api/works/${idWork}/`, {
                method: 'PATCH',
                headers: {

                    'Authorization': 'Bearer ' + tokenAuth,
                },
                body: form_data,
            });
            if (response.ok && ssIsUpdate) {

                let data = await response.json();

                // Use Promise.all to wait for all delete requests to complete
                await Promise.all(EditWork.screenshots.map(async (ss) => {

                    if (ss.id) {
                        let res = await fetch(`https://kiberonevl.ru/api/screenshots/${ss.id}/`, {
                            method: 'DELETE',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + tokenAuth,
                            },
                        });
                    }
                }));

                // Proceed with the rest of the code after all delete requests are completed
                for (let ss of work.screenshots) {
                    let form_data_screenshots = new FormData();
                    form_data_screenshots.append('work', data.id);
                    form_data_screenshots.append('screenshot', ss);

                    let ss_response = await fetch(`https://kiberonevl.ru/api/screenshots/create/`, {
                        method: 'POST',
                        headers: {
                            'Authorization': 'Bearer ' + token,
                        },
                        body: form_data_screenshots,
                    });


                    if (!ss_response.ok) {
                        alert('Что-то пошло не так!', response.status, '!');
                    } else {
                    }
                }


                setLoading(false)
                onClose();
                window.location.reload()

            } else if (response.status === 400) {
                alert('Введены некорректные данные!')
                setWork({ ...work, is_public: null })
                setLoading(false)
            } else if (response.status === 401) {
                alert('Токен авторизации устарел. Пожалуйста, войдите в аккаунт заново.')
                setLoading(false)
                logoutUser()
            } else {
                //alert('Что-то пошло не так!', response.status, '!')
                setLoading(false)
                onClose();
                window.location.reload()
            }
        }
    }


    function handleFileChange(event) {

        event.preventDefault();
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setWork({ ...work, file: selectedFile });
            setSelectedFile(selectedFile);
            setfileIsUpdate(true)
            setErrorWork(null);
        } else {
            // Handle the case where no file is selected
            console.error("No file selected");
        }
    }

    function handleDeleteScreenshot(index) {
        setssIsUpdate(true)
        const updatedScreenshots = [...screenshots];
        updatedScreenshots.splice(index, 1);
        setScreenshots(updatedScreenshots);
        // Also update the array src if needed
        const updatedSrc = [...src];
        updatedSrc.splice(index, 1);
        setSrc(updatedSrc);

        setWork((prevWork) => {
            const newScreenshots = [...prevWork.screenshots];
            newScreenshots.splice(index, 1);

            return {
                ...prevWork,
                screenshots: newScreenshots,
            };
        });
    }


    const fileInputRef = useRef(null);



    const handleInputClick = () => {
        // Вызывает метод click на input, эмулируя клик на input
        fileInputRef.current.click();
    };

    const [isFocused, setIsFocused] = useState(false);
    function hide_placeholder() {
        setIsFocused(true);
    }

    function handleBlur() {
        setIsFocused(false);
    }

    function validateInput(e) {
        e.preventDefault();
        if (isLoading) {
            alert('Подождите, идет загрузка')
            return
        }
        if (!work?.title || /^\s*$/.test(work?.title)) {
            setErrorTitle("Проект должен иметь название")
            return
        }
        if (work.module == undefined) {
            setErrorModal("Выберите модуль проекта")
        }
        // if (work?.screenshots.length == 0) {
        //     seterrorScreenshots("Проект должен иметь хотябы один скриншот")
        //     return
        // }
        if (work?.file == null) {
            setErrorWork('В проект необходимо добавить файл с работой')
            return
        }
        if (work?.file.size == 0) {
            setErrorWork('Файл с работой не должен быть пустым')
            return
        }
        if (work?.file.size >= 512 * 1024 * 1024) {
            setErrorWork('Размер файла не должен превышать 512 Мбайт')
            return
        }

        setOpenPublishModal(true)
    }
    return !isLoading && isOpen ? (
        <div className={"modal_edit modal_active"}>
            <div className=" container_edit-work " onMouseDown={(e) => e.stopPropagation()}>
                <div className='header_add_new_work'>
                    <button type="button" onClick={() => onClose()} ><img style={{ width: "24px" }} className={"modal__close_img"} src={closeButton} /></button>
                </div>
                <form className='form_add_new_work'>
                    <div className='form_uploader_work'>
                        <h2 className='title'>Редактировать работу</h2>
                        <div className='text_field'>
                            <div>
                                <InputWithLabel
                                    textLabel={'Название работы'}
                                    idInput={'title_new_work'}
                                    placeholder={'Введите название работы'}
                                    isText={false}
                                    errorStatus={errorTitle}
                                    onChange={handleTitleChange}
                                    defaultValue={work?.title || ""} />
                                {errorTitle === "200" ? null : <span style={{ color: "#EF4545", marginLeft: "3%" }}>{errorTitle}</span>}

                            </div>
                            <div>
                                <CustomDropdown modules={modules} onSelectModule={handleModuleChange} def={work.module} />
                                {errorModal === null ? null : <span style={{ color: "#EF4545", marginLeft: "3%", marginTop: "2px" }}>{errorModal}</span>}</div>


                        </div>

                        <div className="file-input-container">
                            <label className="file-input-label">
                                Загрузите файл с работой
                            </label>
                            <div className='uploader_file'>
                                <div className="file-info">
                                    {selectedFile ? (
                                        <span>{selectedFile.name}</span>
                                    ) : (
                                        <span>Файл не выбран</span>
                                    )}

                                </div>

                                <label className='file_upload_btn'><input
                                    id="files"
                                    name="files"
                                    type="file"
                                    onChange={handleFileChange}
                                    className="file-input"
                                />Загрузить</label>


                            </div>
                            {errorWork === null ? <div className='mark_for_size_file'>Файл не больше 500 мб </div> : <span style={{ color: "#EF4545", marginLeft: "1%" }}>{errorWork}</span>}
                        </div>
                    </div>
                    <div className='paint'>
                        <div>Загрузить скриншоты работы</div>
                        <div className='screenshots_gellary_container'>

                            <div className='gellary_screenshots'>
                                {src.map((item, index) => (<div>
                                    <div className="box-with-image" key={index} style={{ backgroundImage: `url(${item})` }}>
                                        <button type="button" className="scroos_screenshots" onClick={() => handleDeleteScreenshot(index)}>
                                            <img src={closeBlue} />
                                        </button>
                                    </div>
                                    {index === 0 ? <div className='add_work__photo__main_photo'>Основное фото</div> : null}
                                </div>
                                ))}
                            </div>
                            <div className='input_new_screenshots' onClick={handleInputClick}>
                                <img src={add_photo_svg} />
                                <div>Добавить изображение</div>
                                <input type="file"
                                    required
                                    multiple
                                    accept="image/*"
                                    onChange={handleScreenshotsChange}
                                    ref={fileInputRef} />
                            </div>
                            {errorScreenshots === null ? null : <span style={{ color: "#EF4545", marginLeft: "3%" }}>{errorScreenshots}</span>}
                        </div>
                    </div>
                    <div className='description_work'>
                        <div className='title_description_work'>Описание к работе</div>
                        <textarea placeholder={isFocused ? '' : 'Оставить описание к работе (необязательно)'}
                            value={work.comment}
                            onChange={handleCommentChange}
                            name="aboutWork"
                            onFocus={hide_placeholder}
                            onBlur={handleBlur}
                            className='textarea_description_work' />
                    </div>
                    <div className='sub_add_work'><SubmitButton content={"Готово"} w={280} h={48} onClick={(e) => validateInput(e)} /></div>
                    {isOpenPublishModal ? (
                        <PublishModal
                            isOpen={isOpenPublishModal}
                            onClose={() => setOpenPublishModal(false)}
                            toAgree={handleCheckChangeIsModal}
                            toRefuse={handleCheckChangeIsNotModal}
                            question="Добавить работу в галерею?"
                        />
                    ) : null}
                </form>
            </div>
        </div>
    ) : <LoadingScreen />
}